import React from 'react'
import './Performancecard.css'

const Permormance = ({teamname, bcolor, bwidth}) => {
    // // console.log(bwidth)
  return (
    <div className='performance-container'>
        <div className='performance-container-top'>
            <span className='text-[10px]'>{teamname}</span>
        </div>

        <div className='budget-loader-container'>
          <span className='text-[10px]'>{bwidth}%</span>
          <div className='performance-container-bottom'>
              <div className='performance-loader text-[10px]' style={{ backgroundColor: `${bcolor}`, width: `${bwidth}%`}} />
          </div>
        </div>
    </div>
  )
}

export default Permormance
import React from 'react'
import './Navbar.css'
// import sabreicon from "../../assets/sabre2.png"
import {Link} from 'react-router-dom'

const Navbar = () => {

  return (
    <div className='navbarContian gap-3'>
        <Link to="/" className='flex h-full flex items-center w-[200px]'><div id='imgIcon' className='min-w-[120px]'/></Link>
        <div className='min-w-[300px] w-full text-[20px] font-[600] topbar:text-[14px]'>Reward System Admin Portal</div>
    </div>
  )
}

export default Navbar
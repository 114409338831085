const Countrydata = {
    total: 10000,
    data: [
        {
            country: 'Nigeria',
            amount: 1500
        },
        {
            country: 'Ghana',
            amount: 1200
        },
        {
            country: 'Mali',
            amount: 800
        },
        {
            country: 'Cote de voir',
            amount: 1800
        },
        {
            country: 'Benin',
            amount: 500
        },
        {
            country: 'Gabon',
            amount: 600
        },
        {
            country: 'Senegal',
            amount: 900
        },
        {
            country: 'Marutania',
            amount: 300
        }
    ]
}

export default Countrydata
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { GiSwapBag } from "react-icons/gi";
import NumberFormat from "react-number-format";
import "./Boardcard.css";
import { FaGlobeAfrica, FaBuilding } from "react-icons/fa";

const Boardcard = ({ val, backgrd, maxV, minV, words, currency }) => {
  // console.log(val, maxV, minV,)
  const Iconobj = {
    1: <GiSwapBag id="money-bag" />,
    2: <FaBuilding id="money-bag" />,
    3: <FaGlobeAfrica id="money-bag" />,
  };

  const percentage = val;
  return (
    <div className="bcrd-left-container">
      <CircularProgressbar
        value={percentage}
        // text={`${percentage}%`}
        maxValue={parseInt(maxV)}
        minValue={parseInt(minV)}
        circleRatio={1}
        strokeWidth={6}
        styles={{
          path: {
            stroke: `rgba(${backgrd}, ${percentage / parseInt(maxV)})`,
            strokeLinecap: "butt",
            transition: "stroke-dashoffset 1s ease-in 0s",
            transform: "rotate(0.0turn)",
            transformOrigin: "center center",
          },
          trail: {
            stroke: "#d5d5d5",
            strokeLinecap: "butt",
            transform: "rotate(0.00turn)",
            transformOrigin: "center center",
          },
          text: {
            fill: "#828181",
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "Fira Sans Condensed, sans-serif",
          },
          background: {
            fill: "#3e98c7",
          },
        }}
      />
      <div id="id-arrowid" className="bcrd-right-container">
        <div>{Iconobj[words]}</div>
        {words === 1 ? (
          <span className="text-[14px] font-[700]">
            {
              <NumberFormat
                value={val}
                displayType={"text"}
                thousandSeparator={true}
                prefix={currency + " "}
              />
            }
          </span>
        ) : (
          <span className="text-[14px] font-[700]">{val}</span>
        )}
      </div>
    </div>
  );
};

export default Boardcard;

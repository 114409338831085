const Regionsjson = [
  { label: "Central West Africa ADMIN", value: "CWA_Admin" },
  { label: "Central West Africa Finance", value: "CWA_Finance" },
  { label: "Developer", value: "Dev" },
  { label: "Nigeria", value: "NG" },
  { label: "Mali", value: "ML" },
  { label: "Côte d'Ivoire", value: "CI" },
  { label: "Ghana", value: "GH" },
  { label: "Benin", value: "BJ" },
  { label: "Mauritania", value: "MR" },
  { label: "Senegal", value: "SN" },
  { label: "Togo", value: "TG" },
  { label: "Niger", value: "NE" },
  { label: "Cameroon", value: "CM" },
  { label: "Liberia", value: "LR" },
  { label: "Gabon", value: "GB" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Guinea", value: "GN" },
  { label: "Gambia", value: "GM" },
  { label: "Developer", value: "Dev" },
  { label: "Lagos Main-Land", value: "LGMLND" },
];

export default Regionsjson;

const claimsFunction = (agencyData, regionInfo, exchangeRates) => {
  return agencyData.map((agency) => {
    const region = regionInfo?.find(
      (r) => r.region_code === agency.region_code
    );
    const rate = exchangeRates?.find(
      (rate) => rate.region_code === agency.region_code
    );

    return {
      ...agency,
      amountPerDollar: rate ? rate.amountPerDollar : null,
      amountPerLocalCurrency: rate ? rate.amountPerLocalCurrency : null,
      currency: region ? region.currency : null,
      name: region ? region.name : null,
    };
  });
};

export default claimsFunction;

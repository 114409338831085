export const AllflownTickets = (flown, exrate) => {
  const result = flown.map((itemA) => {
    // Find the matching item in array exrate based on region_code
    const matchingItemB = exrate?.find(
      (itemB) => itemB.region_code === itemA.region_code
    );

    // If a matching item is found, calculate ticketequv and USDequv
    if (matchingItemB) {
      const ticketequv = matchingItemB.amountPerDollar;
      const USDequv = parseFloat(
        (ticketequv / matchingItemB.amountPerLocalCurrency).toFixed(2)
      );
      return {
        ...itemA,
        ticketequv,
        USDequv,
      };
    } else {
      // If no matching item is found, return itemA as it is
      return itemA;
    }
  });

  return result;
};

import React,{useState} from 'react'
import './Sidebar.css'
import { FaShoppingCart, FaGlobeAfrica, FaBuilding } from "react-icons/fa";
import { RiDashboardFill, RiRefund2Line } from "react-icons/ri";
import { GiCash, GiWallet } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { MdTune } from "react-icons/md";
import { RiLogoutCircleFill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { Store, resetStore } from '../../Redux/store';
import { captureButtonClick } from '../../Redux/Actions/buttonClick';
import { useSelector } from 'react-redux';

const Sidebar = ({changeWidth}) => {
  const {role, activeclaims} = useSelector((state) => state.datareponse);
    
    const initialState = [
        { value: "Main", selected: false },
        { value: "Claims", selected: true },
        { value: "Claim", selected: true },
        { value: "Budget", selected: true },
        { value: "Wallet", selected: true },
        { value: "Region", selected: true },
        { value: "Promo", selected: true },
        { value: "exrate", selected: true },
        { value: "Directors", selected: true },
        { value: "Agencies", selected: true },
        { value: "Settings", selected: true },
        { value: "Logout", selected: true}
    ];
    
    const [tabs, setTabs] = useState(initialState)

    const iconsObjt= {
        1: <RiDashboardFill id='bnw-sbc-mainicon'/>,
        2: <FaShoppingCart id= 'bnw-sbc-mainicon'/>,
        3: <HiUserGroup id= 'bnw-sbc-mainicon'/>,
        4: <FaBuilding id= 'bnw-sbc-mainicon'/>,
        5: <MdTune id= 'bnw-sbc-mainicon'/>,
        6: <RiLogoutCircleFill id='bnw-sbc-mainicon'/>,
        7: <GiCash id='bnw-sbc-mainicon'/>,
        8: <FaGlobeAfrica id='bnw-sbc-mainicon'/>,
        9: <GiWallet id='bnw-sbc-mainicon'/>,
        10: <RiRefund2Line id='bnw-sbc-mainicon'/>
    }

    const actives = "10k+";

    const ResetStore = () => {
        resetStore()
    };

    const handleButClick = (e) =>{
        if(e.target.id !== 'Logout'){
          Store.dispatch(captureButtonClick(e.target.id))
        } else{
          // Call Logout Function
        }
    
        setTabs((prevTabs) =>
              prevTabs.map((prevTab) =>
                prevTab.value === e.target.id
                  ? { ...prevTab, selected: false }
                  : { ...prevTab, selected: true }
              )
        );
      }

    return (
    <div className={changeWidth ? 'bnw-sidebar-container' : 'bnw-sidebar-container-clicked'}>
        <ul>
            <li  className={(tabs.find(currentmenu => currentmenu.value === 'Main')).selected ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="/dashboard" className='a' id="Main" onClick={handleButClick}>
                    <span id="Main" onClick={handleButClick}>{iconsObjt[1]}</span>
                    <span id="Main" onClick={handleButClick}>Main Page</span>
                </Link>
            </li>

            {!role.includes("ADMIN") ? "" : 
            <li  className={(tabs.find(currentmenu => currentmenu.value === 'Budget')).selected ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="" className='a' id="Budget" onClick={handleButClick}>
                    <span id="Budget" onClick={handleButClick}>{iconsObjt[10]}</span>
                    <span id="Budget" onClick={handleButClick}>Budget</span>
                </Link>
            </li>}

            {!role.includes("ADMIN") ? "" : 
            <li  className={(tabs.find(currentmenu => currentmenu.value === 'Claim')).selected ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="" className='a' id="Claim" onClick={handleButClick}>
                    <span id="Claim" onClick={handleButClick}>{iconsObjt[7]}</span>
                    <span id="Claim" onClick={handleButClick}>Claims</span>
                </Link>
            </li>}

            {role !== "DIRECTOR" ? "" :
            <li className={(tabs.find(currentmenu => currentmenu.value === 'Claims')).selected ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="" className='a' id="Claims" onClick={handleButClick}>
                    <span id="Claims" onClick={handleButClick}>{iconsObjt[7]}</span>
                    <span id="Claims" onClick={handleButClick}>Claims</span>
                    <div>{activeclaims.toString().length < 4 ? activeclaims : actives}</div>
                </Link>
            </li>}

            {!role.includes("ADMIN") ? "" :
            <li className={(tabs.find(currentmenu => currentmenu.value === 'Wallet')).selected ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="" className='a' id="Wallet" onClick={handleButClick}>
                    <span id="Wallet" onClick={handleButClick}>{iconsObjt[9]}</span>
                    <span id="Wallet" onClick={handleButClick}>Pocket</span>
                </Link>
            </li>}

            {!role.includes("ADMIN") ? "" : 
            <li  className={(tabs.find(currentmenu => currentmenu.value === 'Region')).selected ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="" className='a' id="Region" onClick={handleButClick}>
                    <span id="Region" onClick={handleButClick}>{iconsObjt[8]}</span>
                    <span id="Region" onClick={handleButClick}>Regions</span>
                </Link>
            </li>}

            {/* {!role.includes("ADMIN") ? "" : 
            <li  className={promo ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="" className='a' id="Promo" onClick={handleButClick}>
                    <span id="Promo" onClick={handleButClick}>{iconsObjt[7]}</span>
                    <span id="Promo" onClick={handleButClick}>Promo</span>
                </Link>
            </li>} */}

            {!role.includes("ADMIN") ? "" : 
            <li  className={(tabs.find(currentmenu => currentmenu.value === 'exrate')).selected ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="" className='a' id="exrate" onClick={handleButClick}>
                    <span id="exrate" onClick={handleButClick}>{iconsObjt[7]}</span>
                    <span id="exrate" onClick={handleButClick}>Exchange Rate</span>
                </Link>
            </li>}

            {!role.includes("ADMIN") ? "" : 
            <li className={(tabs.find(currentmenu => currentmenu.value === 'Directors')).selected ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="" className='a' id="Directors" onClick={handleButClick}>
                    <span id="Directors" onClick={handleButClick}>{iconsObjt[3]}</span>
                    <span id="Directors" onClick={handleButClick}>Directors</span>
                </Link>
            </li> }

            <li  className={(tabs.find(currentmenu => currentmenu.value === 'Agencies')).selected ? 'bnw-sidbar-container' : 'active'} id="Agencies" onClick={handleButClick}>
                <Link to="" className='a' id="Agencies" onClick={handleButClick}>
                    <span id="Agencies" onClick={handleButClick}>{iconsObjt[4]}</span>
                    <span id="Agencies" onClick={handleButClick}>Agencies</span>
                </Link>
            </li>

            {role !== "ADMI" ? "" : 
            <li  className={(tabs.find(currentmenu => currentmenu.value === 'Settings')).selected ? 'bnw-sidbar-container' : 'active'} onClick={handleButClick}>
                <Link to="" className='a' id="Settings" onClick={handleButClick}>
                    <span id="Settings" onClick={handleButClick}>{iconsObjt[5]}</span>
                    <span id="Settings" onClick={handleButClick}>Settings</span>
                </Link>
            </li>}

            <li  className={(tabs.find(currentmenu => currentmenu.value === 'Logout')).selected ? 'bnw-sidbar-container' : 'active'} id="Logout" onClick={ResetStore}>
                <Link to="/" className='a' id="Logout" onClick={ResetStore}>
                    <span  id="Logout" onClick={ResetStore}>{iconsObjt[6]}</span>
                    <span  id="Logout" onClick={ResetStore}>Logout</span>
                </Link>
            </li>
        </ul>
    </div>
  )
}

export default Sidebar
// export const server = "https://estate-mgt-backend.omotoyecda.org/ems";

export const API = "https://admin.sabrecwa.com/backend";
export const SeerbitAPI =
  "http://ec2-3-20-158-74.us-east-2.compute.amazonaws.com:8080";
// export const NodeServer_API = "https://9626-41-204-236-230.ngrok-free.app/bookandwin";
export const Paystack_url = "https://api.paystack.co";

// export const NodeServer_API = "http://localhost:4000/bookandwin";

export const NodeServer_API =
  "https://incentive.sabrecwa.com/backend/bookandwin";

const generateChartData = (financeAllocation, financeApprovedBudget) => {
  const totalBudget = financeApprovedBudget || 1; // Avoid division by zero

  const data = financeAllocation.map((allocation) => {
    return parseInt(((allocation.allocation / totalBudget) * 100).toFixed(1));
  });

  const totalPercentage = data
    .reduce((acc, percentage) => acc + percentage, 0)
    .toFixed(1);

  // console.log(totalPercentage);
  const remainingPercentage = 100 - totalPercentage;

  const labels = financeAllocation.map((allocation) => allocation.label);

  return {
    data: [...data, remainingPercentage],
    label: [...labels, "Remaining"],
  };
};

export default generateChartData;

import React, { useState, useEffect } from "react";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import "./page.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import Regionsjson from "../Component/Cards/regionsjson";
import { useSelector } from "react-redux";
import { API } from "../server";
import { CSVLink } from "react-csv";
import { FiUpload } from "react-icons/fi";
import { IoPersonCircleSharp } from "react-icons/io5";

const Director = ({ setloading, setadd, nameSearch }) => {
  const criteria = useSelector((state) => state.searchdata.searchtext);

  const { directordata } = useSelector((state) => state.datareponse);

  const [cookies] = useCookies(["Token"]);
  const [incomingdata, setIncomingdata] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [exportpro, setExportpro] = useState(false);

  const itemsPerpage = 10; //Number of items to be displayed per page
  const pageCount = Math.ceil(incomingdata.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  useEffect(() => {
    setIncomingdata(directordata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directordata]);

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const handleAddButtn = () => {
    setadd();
  };

  var newdataset = incomingdata.filter((filteredItem) => {
    if (criteria === "" && nameSearch === "") {
      return filteredItem;
    } else if (criteria !== "" && nameSearch === "") {
      return filteredItem;
    } else if (criteria === "" && nameSearch !== "") {
      return filteredItem;
    } else if (criteria !== "" && nameSearch !== "") {
      if (criteria === "Region") {
        if (
          Regionsjson.find((m) => m.value === filteredItem.region_code)
            .label.toLowerCase()
            .includes(nameSearch.toLowerCase())
        ) {
          return filteredItem;
        }
      } else {
        return filteredItem;
      }
    }
  });

  const csvHeaders = [
    { label: "Id", key: "ID" },
    { label: "Firstname", key: "first_name" },
    { label: "Lastname", key: "last_name" },
    { label: "Email Address", key: "email" },
    { label: "Region", key: "region_code" },
    { label: "Role", key: "role" },
  ];

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const handleExportCSV = () => {};

  return (
    <>
      <div className="bnw-director-container flex flex-col gap-2">
        <div className="w-full flex flex-col gap-1">
          <div className="w-full justify-between flex bg-[#e50000] p-1">
            <div className="relative flex justify-center items-center">
              <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
                DIRECTORS
              </span>

              <IoPersonCircleSharp size={25} color="#fff" />
            </div>

            <CSVLink data={newdataset} headers={csvHeaders}>
              <div
                className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
                  exportpro ? "bg-[white]" : "bg-[#e50000]"
                }`}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                onClick={handleExportCSV}
              >
                <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
                <span
                  className={`px-2 py-1 cursor-pointer ${
                    exportpro ? "text-[#e50000]" : "text-[#fff]"
                  } text-[12px] font-[600]`}
                >
                  Export
                </span>
              </div>
            </CSVLink>
          </div>

          <table className="bnw-claims-table">
            <thead id="bnw-claims-table-head">
              <tr>
                <th scope="col">ID</th>
                <th scope="col" className="text-[14px]">
                  Name
                </th>
                <th scope="col" className="text-[14px]">
                  Email Address
                </th>
                <th scope="col" className="text-[14px]">
                  Region
                </th>
                <th scope="col" className="text-[14px]">
                  ROLE
                </th>
                {/* <td>Created By</td> */}
              </tr>
            </thead>
            <tbody>
              {newdataset
                .filter((items) => items.region_code !== "DEV")
                .reverse()
                .slice(pagesVisited, pagesVisited + itemsPerpage)
                .map((item, index) => (
                  <>
                    {item.first_name !== "Segun" && (
                      <tr key={index}>
                        <td data-label="ID" className="text-[11px]">
                          {index + 1}
                        </td>
                        <td data-label="NAME" className="text-[11px]">
                          {item.first_name + " " + item.last_name}
                        </td>
                        <td data-label="EMAIL" className="text-[11px]">
                          {item.email}
                        </td>
                        <td data-label="REGION" className="text-[11px]">
                          {
                            Regionsjson.find(
                              (m) =>
                                m.value.toLowerCase() ===
                                item.region_code.toLowerCase()
                            ).label
                          }
                        </td>
                        <td data-label="ROLE" className="text-[11px]">
                          {item.role}
                        </td>
                        {/* <td>{item.status}</td> */}
                      </tr>
                    )}
                  </>
                ))}
            </tbody>
          </table>
        </div>

        <div className="bnw-table-pgnate-contain">
          <button id="addbitn" className="text-[13px]" onClick={handleAddButtn}>
            Add
          </button>
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </>
  );
};

export default Director;

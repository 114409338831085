import React,{useState, useEffect} from 'react'
import './style.css'
import axios from 'axios'
import { CgCloseO } from "react-icons/cg";
import Error from './Error'
import LoadVeriy from './Loading/LoadVeriy';
import { useCookies } from 'react-cookie';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { API } from '../server';

const Confirmpg = ({setloading, closeOverlay}) => {
  const {treatedclaim} = useSelector((state) => state.treatclaim);
  const {cids, appClm} = treatedclaim

  const [cookies] = useCookies(['Token']);
  const [confirmresponse, setConfirmresponse] = useState(0)
  const [responses, setResponses] = useState("")
  const [confirmload, setConfirmload] = useState(false)
  const [expiration, setExpiration] = useState(false)
  const [claimtreat, setClaimtreat] = useState({
    claimid : 0 ,
    approvedClaims: 0
  })

  useEffect(()=>{ 
    setClaimtreat({claimid: cids, approvedClaims: appClm === 1 ? false : true, treatedClaims:cids === 1 ? false : true})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const closeConfirm = () =>{
    closeOverlay()
  }

  const handleApprove = async() =>{
    setConfirmload(true)
    try {
      setloading({
        statuscode: 1,
        message: "Processing...",
        type: 1
      })
      var config = {
        method: 'POST',
        url: `${API}/updateClaim`,
        data: {
          claim_id: claimtreat.claimid,
          status: 1
        },
        headers: { 
          Authorization: "Bearer " + cookies.Token
        }
      };

      // // console.log(config.data)
  
      await axios(config).then((response) => {
        if(response.data.success === true){
          setloading({
            statuscode: 0,
            message: "",
            type: 0
          })
          window.location.reload(true);
        }
      });
    } catch(e){
      if(e.response.status === 401){
        setloading({                      // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3
        })
      } else{
        setloading({
          statuscode: 2,
          message: e.message,
          type: 2
        })
      }
    }
  }

  const handleDecline = async() =>{
    setConfirmload(true)
    try {
      setloading({
        statuscode: 1,
        message: "Processing...",
        type: 1
      })

      var config = {
        method: 'POST',
        url: `${API}/updateClaim`,
        data: {
          claim_id: claimtreat.claimid,
          status: 2
        },
        headers: { 
          Authorization: "Bearer " + cookies.Token
        }
      };
  
      await axios(config).then((response) => {
        // // console.log(response.data.data);
        if(response.data.success === true){
          setloading({
            statuscode: 0,
            message: "",
            type: 0
          })
          window.location.reload(true);
        }
      });
    } catch(e){
      if(e.response.status === 401){
        setloading({                      // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3
        })
      } else{
        setloading({
          statuscode: 2,
          message: e.message,
          type: 2
        })
      }
    }
  }

  return (
    <div className='bnw-confirm-pg'>
        <div className='bnw-confirm-cont gap-3 min-w-[350px]'>
          <div className='bnw-confirm-head'>
            <span>Treat Claim</span>
            <CgCloseO id='bnw-conf-icon' onClick={closeConfirm}/>
          </div>
          
          <div className='w-full text-[14px] text-center'>By Treating Claim as Approve, you conscent to make a payement of <strong><NumberFormat value={1600} displayType={'text'} thousandSeparator={true} prefix={'XOF '} /></strong> to account belonging to <strong>xxx</strong></div>

          <div className='bnw-confm-but-contain'>
            <button id='bnw-approve-but' onClick={handleApprove}>Approve</button>
            <button id='bnw-decline-but' onClick={handleDecline}>Decline</button>
          </div>
        </div>
    </div>
  )
}

export default Confirmpg
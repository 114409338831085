import React, {useState, useEffect} from 'react'
import "./togglebuttn.css"

const Toggles = ({setState, index, currentState, regionState, emailState}) => {
    // // console.log(currentState)

    const [modee, setModee] = useState(currentState ? true : false)   

    useEffect(() => {
        setModee(currentState ? true : false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentState])
    
    const toggleState = () =>{
        setState({id: index, mail: emailState, reg: regionState, status: modee ? 0 : 1 })
    }

    return (
        <div className='navbar-contain'>
            <div className = {(modee ? "toggle_contain_inv" : "toggle_contain")} onClick={toggleState}>
                {modee ? <div id="darkmode" className='text-[10px]'>YES</div> : <div id="lightmode" className='text-[10px]'>NO</div> }
            </div>
        </div>
        
    );
}

export default Toggles
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { processData } from "../../utils/processflownPointsScript";
import { formattedFinanceChart } from "../../utils/mixedfinancechart";
import { getCurrentYearMonths } from "../../utils/currentyearmontharray";
import { Mixedticketequivalent } from "../../utils/mixedticketequivalent";

const FinancialPointsperCostChart = () => {
  const { selectedyear } = useSelector((state) => state.buttonclick);

  const { pointsdata, exchangerate } = useSelector(
    (state) => state.datareponse
  );

  let onlyFlownPoints = pointsdata.filter(
    (pointobject) => pointobject.ticket_status === 1
  );

  // format points based on tickets equivalent
  let ticketEquivalent = Mixedticketequivalent(
    onlyFlownPoints,
    exchangerate.data
  );

  // format points based on tickets only
  const result = processData(onlyFlownPoints);
  let formattedChartdta = formattedFinanceChart(result);

  // console.log(formattedChartdta);

  const [mycheckbox, setMycheckbox] = useState(false);

  let chartdata = {
    expenditure: !mycheckbox
      ? ticketEquivalent[selectedyear] === undefined
        ? []
        : ticketEquivalent[selectedyear].TeamII
      : ticketEquivalent[selectedyear] === undefined
      ? []
      : ticketEquivalent[selectedyear].TeamI,
    tickets: !mycheckbox
      ? formattedChartdta[selectedyear] === undefined
        ? []
        : formattedChartdta[selectedyear].TeamII
      : formattedChartdta[selectedyear] === undefined
      ? []
      : formattedChartdta[selectedyear].TeamI,
  };

  // console.log(chartdata)

  const chartData = {
    series: [
      {
        name: "Ticket Eqv.",
        type: "column",
        data: chartdata.expenditure,
      },
      {
        name: "Tickets",
        type: "line",
        data: chartdata.tickets,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 2],
      },
      title: {
        text: "",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: getCurrentYearMonths(),
      xaxis: {
        type: "datetime",
      },
      yaxis: [
        {
          title: {
            text: "Apprx. Ticket Eqv. (.000 USD)",
          },
        },
        {
          opposite: true,
          title: {
            text: "Tickets",
          },
        },
      ],
    },
  };

  return (
    <div className="w-full flex flex-col justify-center bg-white rounded-[10px] py-[20px] px-[30px] gap-2">
      <div className="flex items-center justify-between w-full">
        <span className="text-[13px] font-[800] text-[#323C4D]">
          Flown Tickets vs Ticket Eqv. USD
        </span>

        <div className="w-fit gap-2 flex items-center text-[12px] font-[500]">
          <span>Year: </span>
          <span>{selectedyear}</span>
        </div>

        <div className="flex items-center gap-4">
          <div className="flex item-center gap-1">
            <input
              type="checkbox"
              onChange={() => setMycheckbox(!mycheckbox)}
              checked={mycheckbox ? true : false}
              className="cursor-pointer"
            />
            <label
              htmlFor="checkbox"
              className="text-[12px] font-[500] text-[green]"
            >
              Team I
            </label>
          </div>

          <div className="flex item-center gap-1">
            <input
              type="checkbox"
              onChange={() => setMycheckbox(!mycheckbox)}
              checked={!mycheckbox ? true : false}
              className="cursor-pointer"
            />
            <label
              htmlFor="checkbox"
              className="text-[12px] font-[500] text-[red]"
            >
              Team II
            </label>
          </div>
        </div>
      </div>

      <div className="w-full min-h-[300px]">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={350}
          width={"100%"}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default FinancialPointsperCostChart;

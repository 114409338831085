import { createAction, createReducer } from "@reduxjs/toolkit";

const Capture_filtered_text = createAction("Capture_filtered_text");

const initialState = {
  searchtext: {
    criteria: "",
    agencyname: "",
  },
};

export const searchReducer = createReducer(initialState, (builder) => {
  builder.addCase(Capture_filtered_text, (state, action) => {
    state.searchtext = action.payload;
  });
});

export const processData = (data) => {
  const result = [];

  // Create a map to store the monthly ticket_status sums for each team
  const teamSums = new Map();

  // Iterate over each ticket object
  data.forEach((ticket) => {
    const monthYear = new Date(ticket.created_at).toLocaleString("en-US", {
      month: "long",
      year: "numeric",
    });
    const team = ticket.region_code === "NG" ? "I" : "II";

    // Initialize team sum for the month if not present
    if (!teamSums.has(monthYear)) {
      teamSums.set(monthYear, { I: 0, II: 0 });
    }

    // Update the team sum for the month
    teamSums.get(monthYear)[team] += ticket.ticket_status;
  });

  // Convert the map into the desired result format
  teamSums.forEach((sums, monthYear) => {
    result.push({
      team: "I",
      tickets: sums.I,
      monthYear,
    });

    result.push({
      team: "II",
      tickets: sums.II,
      monthYear,
    });
  });

  return result;
};

import React, { useState } from "react";
import "./Sidebar.css";
import { BiSearchAlt2 } from "react-icons/bi";
import { HiMenu } from "react-icons/hi";
import Singleselect from "../Singleselect/Singleselect";
import SortByjson from "../Singleselect/filteredBy";
import { useSelector } from "react-redux";
import { Store } from "../../Redux/store";
import { searchFilter } from "../../Redux/Actions/searchFilter";

const TopSidebar = ({ menuClicked }) => {
  const { fullname, role } = useSelector((state) => state.datareponse);
  const { buttonClicks } = useSelector((state) => state.buttonclick);
  const { criteria, agencyname } = useSelector(
    (state) => state.searchdata.searchtext
  );
  // const [respondscreen, setResponsescreen] = useState(false)

  const handleMenuClick = () => {
    menuClicked();
  };

  const [searchname, setSearchname] = useState("");

  const handleSearch = (e) => {
    setSearchname(e.target.value);
    Store.dispatch(searchFilter({ agencyname: e.target.value, criteria }));
  };

  const handleSelected = (incoming) => {
    Store.dispatch(searchFilter({ criteria: incoming, agencyname }));
  };

  return (
    <div className="bnw-tpsbar-main-container">
      <div className="bnw-tpsbar-container">
        <HiMenu id="bnw-himenu-icon" onClick={handleMenuClick} />
        <div className="flex items-center topbar:hidden">
          {buttonClicks === "Main" ? (
            <span className="text-[20px] font-[700] leading-8 text-[#475366] mr-2">
              Dashboard
            </span>
          ) : buttonClicks === "Budget" ? (
            <span className="text-[20px] font-[700] leading-8 text-[#475366] mr-2">
              Budget
            </span>
          ) : buttonClicks === "Claim" ? (
            <span className="text-[20px] font-[700] leading-8 text-[#475366] mr-2">
              Claims
            </span>
          ) : buttonClicks === "Directors" ? (
            <span className="text-[20px] font-[700] leading-8 text-[#475366] mr-2">
              Directors
            </span>
          ) : buttonClicks === "Agencies" ? (
            <span className="text-[20px] font-[700] leading-8 text-[#475366] mr-2">
              Agencies
            </span>
          ) : buttonClicks === "Wallet" ? (
            <span className="text-[20px] font-[700] leading-8 text-[#475366] mr-2">
              Report
            </span>
          ) : buttonClicks === "exrate" ? (
            <span className="text-[20px] font-[700] leading-8 text-[#475366] mr-2">
              Exchange Rate
            </span>
          ) : (
            buttonClicks === "Region" && (
              <span className="text-[20px] font-[700] leading-8 text-[#475366] mr-2">
                Regions
              </span>
            )
          )}
        </div>

        <div className="bnw-searchbox-container">
          <div className="dropdwon-sort">
            <Singleselect
              socialjs={SortByjson}
              selectedSocVal={handleSelected}
              placeholder="Search by"
            />
          </div>
          <div className="bnw-search-box-contain">
            <input
              value={searchname}
              onChange={handleSearch}
              placeholder="Search Content..."
              id="bnw-search-box"
              className="text-[13px]"
            />
            <BiSearchAlt2 id="bnw-search-icon" />
          </div>
          <div className="bnw-profile flex gap-3">
            <div className="bnw-profileImg-container p-1">
              <div id="bnw-profileImg" />
            </div>
            <div className="bnw-profile-name w-full">
              <span className="text-[13px]">{fullname}</span>
              <span className="text-[10px]">{role}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSidebar;

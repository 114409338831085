import React from 'react'
import NumberFormat from 'react-number-format'
import Permormance from '../Performancecard/Permormance'
import './Countrybudget.css'

const Countrybudget = ({country, color, colorwidth, amount}) => {
  return (
    <div className='countrybudget-container'>
      <Permormance teamname={country} bcolor={color} bwidth={colorwidth}/>

      <span className='text-[11px]'>{<NumberFormat allowLeadingZeros={true} value={amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</span>
    </div>
  )
}

export default Countrybudget
import React from "react";
import { GiAirplaneArrival, GiAirplaneDeparture, GiCash } from "react-icons/gi";
import { BsCashCoin } from "react-icons/bs";
import NumberFormat from "react-number-format";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { Store } from "../../Redux/store";
import {
  captureButtonClick,
  captureTopPerforming,
} from "../../Redux/Actions/buttonClick";
// import { useSelector } from "react-redux";

const Boxes = ({
  boxtitle,
  icon,
  currency_team1,
  currency_team2,
  team_1_value,
  team_2_value,
  tooltiptext,
}) => {
  //   const { financialdata } = useSelector((state) => state.datareponse);

  const iconObjects = {
    1: <GiCash size={25} color="#333" />,
    2: <GiAirplaneDeparture size={25} color="#333" />,
    3: <GiAirplaneArrival size={25} color="#333" />,
    4: <BsCashCoin size={25} color="#333" />,
    5: <></>,
  };

  const HandleBoxClick = () => {
    if (boxtitle === "CURRENT LIABLILTY") {
      Store.dispatch(captureTopPerforming(3));
    } else if (boxtitle === "EXPECTED LIABLILTY") {
      Store.dispatch(captureTopPerforming(4));
    } else if (boxtitle === "ALL CLAIMED TICKETS") {
      Store.dispatch(captureTopPerforming(5));
    } else if (boxtitle === "ALL UNFLOWN TICKETS") {
      Store.dispatch(captureTopPerforming(6));
    } else if (boxtitle === "ALL FLOWN TICKETS") {
      Store.dispatch(captureTopPerforming(7));
    } else if (boxtitle === "ALL TICKETED BOOKINGS") {
      Store.dispatch(captureTopPerforming(8));
    }
  };

  return (
    <Tooltip TransitionComponent={Zoom} title={tooltiptext} followCursor>
      <div
        className={`bg-[#fff] rounded-md flex flex-col py-2 px-3 border w-fit cursor-default relative min-w-[170px] gap-1 hover:shadow grow ${
          (boxtitle === "EXPECTED LIABLILTY" ||
            boxtitle === "ALL CLAIMED TICKETS" ||
            boxtitle === "ALL UNFLOWN TICKETS" ||
            boxtitle === "ALL FLOWN TICKETS" ||
            boxtitle === "ALL TICKETED BOOKINGS" ||
            boxtitle === "CURRENT LIABLILTY") &&
          "cursor-pointer"
        }`}
        onClick={HandleBoxClick}
      >
        <div className="flex w-full items-center justify-center gap-4">
          <div className="flex gap-2 items-center">
            <span className="w-1.5 h-1.5 rounded-full bg-[green]" />

            <span className="text-[green] text-[10px] font-[500]">Team I</span>
          </div>

          <div className="flex gap-2 items-center">
            <span className="w-1.5 h-1.5 rounded-full bg-[red]" />

            <span className="text-[red] text-[10px] font-[500]">Team II</span>
          </div>
        </div>

        <span className="text-[11px] font-[700]">{boxtitle}</span>

        <div className="w-full flex gap-5 items-center justify-between">
          {iconObjects[icon]}

          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <span className="w-1.5 h-1.5 bg-[green] " />

              <span className="text-[green] text-[14px] font-[500]">
                <NumberFormat
                  value={team_1_value}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={currency_team1}
                />
              </span>
            </div>

            <div className="flex gap-2 items-center">
              <span className="w-1.5 h-1.5 bg-[red] " />

              <span className="text-[red] text-[14px] font-[500]">
                <NumberFormat
                  value={team_2_value}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={currency_team2}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default Boxes;

import React,{useState} from 'react'
import './page.css'

const Settings = () => {
  const [activepg, setActicepg] = useState(false);
  return (
    <>
      {activepg ? <div className='bnw-settings-container'>Settings</div> : <div className='bnw-settings-container_'>Directory Currently Unavailable</div>}
    </>
  )
}

export default Settings
import React, { useState, useEffect } from "react";
// import { BiDownload } from "react-icons/bi";
import "./Cards.css";
import "./budget.css";
import Countrybudget from "./CountryBudget/Countrybudget";
import DoughnutChart from "../Doughnutchart/Doughnut";
// import NumberFormat from "react-number-format";
// import Countrydata from "./budgetJson";
// import Singleselect from "../Singleselect/Singleselect";
// import Monthfilter from "../Singleselect/monthFilter";
import { useSelector } from "react-redux";
import mergeAllocationsWithLabels from "../Mergedallocation/mergedallocations";
import Regionsjson from "./regionsjson";
// import extractLabels from "../Mergedallocation/countryArray";
import { Store } from "../../Redux/store";
import { allocatedCountries } from "../../Redux/Actions/treatClaims";
// import MonthSelector from "../DateSelector/Dateselector";
import { sumAllocationsByYearAndMarket } from "../../utils/summedallocationbyyear";
// import Barchart from "../Barchart/Barchart";
import { groupAllocationsByYear } from "../../utils/budgetoverviewbyyear";

const BudgetOverview = ({ backcolor, text, value, maximum }) => {
  const [mycheckbox, setMycheckbox] = useState(false);

  const { allocations, marketallocationdata } = useSelector(
    (state) => state.datareponse
  );

  const { selectedyear, selectedmonth } = useSelector(
    (state) => state.buttonclick
  );

  // console.log(selectedmonth);

  const currentDate = new Date();

  // console.log(marketallocationdata);

  // Summed up allocation, grouped by the year of creation
  let formattedallocation = sumAllocationsByYearAndMarket(marketallocationdata);

  // console.log(formattedallocation);

  const mergedAllocations = mergeAllocationsWithLabels(
    allocations,
    Regionsjson
  );

  const budgetOverview = groupAllocationsByYear(mergedAllocations);
  // console.log(budgetOverview);

  useEffect(() => {
    Store.dispatch(allocatedCountries(mergedAllocations));
  }, [mergedAllocations]);

  const [PercentValue, setPercentValue] = useState();
  const [claimCount, setClaimCount] = useState();

  const Color = [
    "#FF66E7",
    "#3F1622",
    "#9B6637",
    "#4F16C7",
    "#33B3C7",
    "#1B96E1",
    "#1C43B7",
    "#8B26E1",
  ];

  useEffect(() => {
    SetActiveClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SetActiveClaims = () => {
    setClaimCount(value); // Value of Claim Count coming from the DB
    setPercentValue((claimCount / maximum) * 100);
  };

  const currMonth = new Date().toLocaleString([], {
    month: "long",
  });

  const handleSelected = (incoming) => {
    // console.log(incoming);
  };

  return (
    <div
      className="bnw-budget-container min-w-[400px]"
      style={{ backgroundColor: backcolor }}
    >
      <div className="w-full flex items-center justify-between">
        <span className="text-[#323C4D] text-[13px] font-[800]">{text}</span>

        <div className="w-fit gap-2 flex items-center text-[12px] font-[500]">
          <span>Year: </span>
          <span>{selectedyear}</span>
        </div>

        <div className="flex items-center gap-4">
          <div className="flex item-center gap-1">
            <label
              htmlFor="checkbox"
              className="text-[12px] font-[500] text-[red]"
            >
              Team II
            </label>
          </div>
        </div>
      </div>

      <div className="bnw-body-budget-contn">
        <div className="left-budget-body-contn">
          {selectedmonth !== "All"
            ? budgetOverview[selectedyear] &&
              budgetOverview[selectedyear][selectedmonth] &&
              budgetOverview[selectedyear][selectedmonth].map(
                (items, index) => (
                  <Countrybudget
                    key={index}
                    country={items.label}
                    color={Color[index]}
                    colorwidth={(
                      (items.allocation * 100) /
                      formattedallocation[selectedyear]?.TeamII
                    ).toFixed(1)}
                    amount={items.allocation}
                  />
                )
              )
            : mergedAllocations.map((items, index) => (
                <Countrybudget
                  country={items.label}
                  color={Color[index]}
                  colorwidth={(
                    (items.allocation * 100) /
                    formattedallocation[currentDate.getFullYear()]?.TeamII
                  ).toFixed(1)}
                  amount={items.allocation}
                />
              ))}
        </div>

        <div className="budget-right-pie-container">
          <DoughnutChart
            checkbox={mycheckbox}
            allocate={
              !mycheckbox
                ? formattedallocation[selectedyear] === undefined
                  ? 0
                  : formattedallocation[selectedyear].TeamII
                : formattedallocation[selectedyear] === undefined
                ? 0
                : formattedallocation[selectedyear].TeamI
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BudgetOverview;

import React from "react";
import NumberFormat from "react-number-format";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useSelector } from "react-redux";
import { addDecimal } from "../Functions/Numberformat";

const Walletboxx = ({ text1, curr_date, amount, mycheckbox }) => {
  const { paystackwalletdata } = useSelector((state) => state.datareponse);

  return (
    <Tooltip
      TransitionComponent={Zoom}
      title="Balance Left in Central wallet"
      followCursor
    >
      <div className="grow w-[33%] min-w-[220px] bg-[#fff] shadow-md rounded-md py-6 px-8 flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <span className="text-[11px] font-[500] walletbox:text-[14px]">
            {text1}
          </span>
          <span className="text-[10px] font-[500] walletbox:text-[12px] wallet:font-[400]">
            {curr_date}
          </span>
        </div>

        <div className="flex w-full gap-3 items-start">
          <span className="text-[28px] font-[700]">
            <NumberFormat
              value={!mycheckbox ? amount : addDecimal(paystackwalletdata)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={!mycheckbox ? "$" : "₦"}
            />
          </span>
        </div>
      </div>
    </Tooltip>
  );
};

export default Walletboxx;

const toperformingRegions = (data, regionLabels, countryFlags) => {
  const regionScores = {};

  // // console.log(data);

  data.forEach((entry) => {
    const regionLabel = regionLabels.find(
      (label) => label.value === entry.region_code
    )?.label;

    if (regionLabel) {
      if (!regionScores[regionLabel]) {
        regionScores[regionLabel] = {
          totalScore: 0,
          totalPoints: 0,
          unflownTickets: 0,
          entries: [],
          countryFlag: "", // Added countryFlag
        };
      }

      const totalScore =
        Number(entry.active_points) + Number(entry.unflown_tickets);
      regionScores[regionLabel].totalScore += totalScore;
      regionScores[regionLabel].totalPoints += Number(entry.active_points);
      regionScores[regionLabel].unflownTickets += Number(entry.unflown_tickets);
      regionScores[regionLabel].entries.push({
        active_claims: entry.active_claims,
        active_points: entry.active_points,
        approved_claims: entry.approved_claims,
        cancelled_claims: entry.cancelled_claims,
        invalid_points: entry.invalid_points,
        region_code: entry.region_code,
        total_points: entry.total_points,
        unflown_tickets: entry.unflown_tickets,
        agent_name: entry.agent_name,
        agency_name: entry.agency_name,
        totalScore: totalScore,
      });
    }
  });

  // Map country flags to their respective regions
  const countryFlagsMap = countryFlags.reduce((acc, flag) => {
    acc[flag.region] = flag.url;
    return acc;
  }, {});

  const aggregatedTotalScore = Object.values(regionScores).reduce(
    (total, region) => total + region.totalScore,
    0
  );

  const regionsWithPercentage = Object.keys(regionScores).map((regionLabel) => {
    const { totalScore, totalPoints, unflownTickets, entries } =
      regionScores[regionLabel];
    const percentage = (totalScore / aggregatedTotalScore) * 100 || 0;

    // // console.log(entries);
    return {
      active_claims: entries[0].active_claims,
      active_points: entries[0].active_points,
      approved_claims: entries[0].approved_claims,
      cancelled_claims: entries[0].cancelled_claims,
      invalid_points: entries[0].invalid_points,
      region_code: entries[0].region_code,
      region_label: regionLabel,
      total_points: entries[0].total_points,
      unflown_tickets: entries[0].unflown_tickets,
      agent_name: entries[0].agent_name,
      agency_name: entries[0].agency_name,
      totalScore: totalScore,
      percentage: percentage.toFixed(2),
      countryFlag: countryFlagsMap[regionLabel] || "", // Added countryFlag
    };
  });

  const topPerformingRegions = regionsWithPercentage
    .sort((a, b) => b.totalScore - a.totalScore)
    .slice(0, 5);

  return topPerformingRegions;
};
export default toperformingRegions;

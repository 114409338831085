import React, { useState, useEffect } from "react";
import "./page.css";
import SLoader from "../Component/Loading/SigninLoader";
// import {useLocation} from 'react-router-dom';
import Sidebar from "../Component/Sidebar/Sidebar";
import TopSidebar from "../Component/Sidebar/TopSidebar";
import Body from "./body";
import Claims from "./Claims/claims";
import Director from "./director";
import Agencies from "./agencies";
import Settings from "./settings";
import Confirmpg from "../Component/Confirmpg";
import AddDirectors from "../Component/Cards/AddDirectors";
import axios from "axios";
import { useCookies } from "react-cookie";
import Error from "../Component/Error";
import Promo from "./Promo";
import Regions from "./Regions";
import Loginpg from "./Login";
import Confirms from "../Component/Confirms";
import AddRegions from "../Component/Cards/AddRegions";
import Exchangerate from "./Exchangerate";
import LoadVeriy from "../Component/Loading/LoadVeriy";
import Allocate from "../Component/Allocate/allocate";
import AddPromo from "../Component/Cards/AddPromo";
import Wallet from "./wallet";
import Budget from "./budget";
import Claim from "./Claims/claim";
import { SeerbitDataID } from "../data/data";
import { Store, resetStore } from "../Redux/store";
import {
  claimsData,
  financialData,
  getActiveClaims,
  getAllAgencies,
  getAllDirectors,
  getAllExchangerate,
  getAllRegions,
  getAllocation,
  getApprovedClaims,
  getCanceledClaims,
  getName,
  getRole,
  homepageData,
  marketallocationData,
  paystackTotalDisbursed,
  paystackTransactionHistory,
  paystackWalletBalance,
  pointBreakdown,
  pointsData,
  transactionhistoryData,
} from "../Redux/Actions/dataResponse";
import { pocketDetails } from "../Redux/Actions/pocketDetails";
import { useSelector } from "react-redux";
import { API, NodeServer_API, Paystack_url } from "../server";
import { captureTopPerforming } from "../Redux/Actions/buttonClick";
import Budgetfinance from "../Component/Allocate/budgetfinance";
import { getCurrentDate } from "../utils/currentdate";
import { getMonthNumber } from "../Component/DateSelector/Monthselector";

const Dashboard = ({ setloading }) => {
  const [cookies] = useCookies(["Token"]);
  // const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState(true);
  const [treatConfirm, setTreatConfirm] = useState(false);
  const [canlogin, setCanlogin] = useState(false);
  const [addDirector, setAddDirector] = useState(false);
  const [expiration, setExpiration] = useState(false);
  const [addRegion, setAddRegion] = useState(false);
  const [addProm, setAddProm] = useState(false);
  const [treatemail, SetTreatemail] = useState();
  const [agencyName, setAgencyName] = useState("");
  const seebitAPI =
    "http://ec2-3-20-158-74.us-east-2.compute.amazonaws.com:8080/pockets/pocket/pocket-id";

  const page = useSelector((state) => state.buttonclick.buttonClicks);
  const notice = useSelector((state) => state.buttonclick.newmodal);
  const { selectedmonth, selectedyear } = useSelector(
    (state) => state.buttonclick
  );

  let datefrom = `${selectedyear}-${getMonthNumber(selectedmonth)}-01`;
  let dateto = getCurrentDate();

  const [treats, setTreats] = useState({
    cids: 0,
    appClm: 0,
  });

  const [successful, setSuccessful] = useState({
    statuscode: 0,
    message: "",
    type: "",
  });

  //user1, admin
  useEffect(() => {
    FetchAllClaims();
    FetchTransactionHistroy();
    FetchAllAllocation();
    FetchAllPoints();
    FetchPaystackBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Makerolecall();
    FetchDirectors();
    FetchamountDue();
    Makeseerbitcall();
    FetchPointBreakdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    FetchPaystackTotalDisbursed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datefrom]);

  useEffect(() => {
    FetchPaystackTransactionHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateto]);

  const FetchAllClaims = async () => {
    try {
      var config = {
        method: "GET",
        url: `${API}/claims`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          Store.dispatch(claimsData(response.data.data));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const FetchTransactionHistroy = async () => {
    // console.log("Here")
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/transactionHistory`,
      };

      await axios(config).then((response) => {
        // console.log(response)
        if (response.data.success === true) {
          Store.dispatch(transactionhistoryData(response.data.data));
        }
      });
    } catch (e) {
      // console.log("error: ", e.response);
    }
  };

  const FetchAllAllocation = async () => {
    // // console.log("Here")
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/finance`,
      };

      await axios(config).then((response) => {
        // console.log(response)
        if (response.data.success === true) {
          Store.dispatch(marketallocationData(response.data.data));
        }
      });
    } catch (e) {
      // // console.log("error: ", e.response);
    }
  };

  const FetchAllPoints = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/points`,
      };

      // // console.log(config)

      await axios(config).then((response) => {
        // // console.log(response)
        if (response.data.success === true) {
          Store.dispatch(pointsData(response.data.data));
        }
      });
    } catch (e) {
      // // console.log("error: ", e.response);
    }
  };

  const FetchPaystackBalance = async () => {
    resetStore();

    try {
      var config = {
        method: "GET",
        url: `${Paystack_url}/balance`,
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_PAYSTACK_SECRET_KEY,
        },
      };

      await axios(config).then((response) => {
        // console.log(response.data);
        if (response.data.status === true) {
          Store.dispatch(paystackWalletBalance(response.data.data[0].balance));
        }
      });
    } catch (e) {
      // // console.log("error: ", e.response);
    }
  };

  const FetchPaystackTotalDisbursed = async () => {
    try {
      var config = {
        method: "GET",
        url: `${Paystack_url}/transaction/totals?from=2024-03-31&to=${dateto}`,
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_PAYSTACK_SECRET_KEY,
        },
      };

      await axios(config).then((response) => {
        // console.log(response.data);
        if (response.data.status === true) {
          Store.dispatch(
            paystackTotalDisbursed(response.data.data.total_volume)
          );
        }
      });
    } catch (e) {
      // // console.log("error: ", e.response);
    }
  };

  const FetchPaystackTransactionHistory = async () => {
    try {
      var config = {
        method: "GET",
        url: `${Paystack_url}/transaction?perPage=500&from=2016-02-27T16:12:40.220Z&to=${dateto}`,
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_PAYSTACK_SECRET_KEY,
        },
      };

      console.log(config);

      await axios(config).then((response) => {
        if (response.data.status === true) {
          Store.dispatch(paystackTransactionHistory(response.data.data));
        }
      });
    } catch (e) {
      // // console.log("error: ", e.response);
    }
  };

  const Makerolecall = async () => {
    resetStore();

    try {
      var config = {
        method: "GET",
        url: `${API}/homepage`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === false) {
          Store.dispatch(homepageData(response.data.data));
          Store.dispatch(getAllAgencies(response.data.data.agents));
          Store.dispatch(getActiveClaims(response.data.data.active_claims));
          Store.dispatch(getApprovedClaims(response.data.data.approved_claims));
          Store.dispatch(
            getCanceledClaims(response.data.data.cancelled_claims)
          );
          Store.dispatch(getAllRegions(response.data.data.regions));
          Store.dispatch(getAllExchangerate(response.data.data.exchange_rate));
          Store.dispatch(getRole(response.data.data.role));
          Store.dispatch(
            getName(
              response.data.data.directors_last_name +
                " " +
                Array.from(response.data.data.directors_first_name)[0] +
                "."
            )
          );
          Store.dispatch(
            getAllocation(
              response.data.data === null ? [] : response.data.data.allocations
            )
          );
        }
      });
    } catch (e) {
      if (e.response.status === 401) {
        handleNotice({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        handleNotice({
          statuscode: 2,
          message: e.message,
          type: 2,
        });
      }
    }
  };

  const FetchDirectors = async () => {
    try {
      var config = {
        method: "GET",
        url: `${API}/directors`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(getAllDirectors(response.data.data));
        }
      });
    } catch (e) {
      // console.log(e.response.data.message)
    }
  };

  const FetchamountDue = async () => {
    try {
      var config = {
        method: "GET",
        url: `${API}/finance/home`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(financialData(response.data.data));
          setLoading(false);
        }
      });
    } catch (e) {
      // setLoading(false);
    }
  };

  const Makeseerbitcall = async () => {
    const seerbitObj = SeerbitDataID.filter(
      (filteredobj) => filteredobj.region === "ML"
    );

    try {
      var config = {
        method: "GET",
        url: `${seebitAPI}/${seerbitObj[0].pocketId}`,
        headers: {
          "Public-Key": "SBPUBK_ZMKTO3FAN2RAODYB37RD6XRPBJGPPTWE",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).bearerToken,
        },
      };

      await axios(config).then((response) => {
        if (response.data.message === "Success") {
          Store.dispatch(pocketDetails(response.data.data));
        }
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const FetchPointBreakdown = async () => {
    try {
      var config = {
        method: "GET",
        url: `${API}/finance/points_breakdown`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        // console.log(response.data.data);
        if (response.data.success === true) {
          Store.dispatch(pointBreakdown(response.data.data));
        }
      });
    } catch (e) {
      // setLoading(false);
    }
  };

  const menubarClick = () => {
    setMenu(!menu);
  };

  const TreatedClick = (incoming) => {
    setTreatConfirm(!treatConfirm);
    setTreats({
      cids: incoming.cid,
      appClm: incoming.aclm,
    });
    // // // console.log(`Adeyemi: ${JSON.stringify(incoming)}`)
  };

  const TreatedClicks = (incoming) => {
    setCanlogin(!canlogin);
    SetTreatemail(incoming);
  };

  const handleCloseoverlay = () => {
    setTreatConfirm(false);
    setAddDirector(false);
    setCanlogin(false);
    setAddRegion(false);
    setAddProm(false);
  };

  const handleAddDir = () => {
    setAddDirector(true);
  };

  const handleAddReg = () => {
    setAddRegion(true);
  };

  const handleAddProm = () => {
    setAddProm(true);
  };

  const handlExpiration = () => {
    setExpiration(true);
  };

  const handleNameSearch = (incoming) => {
    setAgencyName(incoming);
  };

  const handleClose = () => {
    setSuccessful({
      statuscode: 0,
      message: "",
      type: "",
    });
  };

  const handleSetCountry = () => {};

  const handleNotice = (props) => {
    setSuccessful({ successful, ...props });
  };
  // Store.dispatch(captureTopPerforming(false));

  return (
    <>
      {loading ? (
        <SLoader type="bars" text="Fetching Data" />
      ) : (
        <div className="bnwd-container">
          <TopSidebar menuClicked={menubarClick} />
          <div className="bnw-botm-container">
            <Sidebar changeWidth={menu} />
            {page === "Main" ? (
              <Body />
            ) : page === "Claims" ? (
              <Claims setloading={handleNotice} setreat={TreatedClick} />
            ) : page === "Claim" ? (
              <Claim setloading={handleNotice} />
            ) : page === "Budget" ? (
              <Budget closeOverlay={handleCloseoverlay} />
            ) : page === "Wallet" ? (
              <Wallet
                setloading={handleNotice}
                closeOverlay={handleCloseoverlay}
                setadd={handleAddReg}
                setExpire={handlExpiration}
                nameSearch={agencyName}
              />
            ) : page === "Region" ? (
              <Regions
                setloading={handleNotice}
                closeOverlay={handleCloseoverlay}
                setadd={handleAddReg}
                setExpire={handlExpiration}
                nameSearch={agencyName}
              />
            ) : page === "Promo" ? (
              <Promo setadd={handleAddProm} setExpire={handlExpiration} />
            ) : page === "exrate" ? (
              <Exchangerate
                setloading={handleNotice}
                closeOverlay={handleCloseoverlay}
                setcountry={handleSetCountry}
              />
            ) : page === "Directors" ? (
              <Director
                setloading={handleNotice}
                setadd={handleAddDir}
                nameSearch={agencyName}
              />
            ) : page === "Agencies" ? (
              <Agencies
                setreat={TreatedClicks}
                setloading={handleNotice}
                nameSearch={agencyName}
              />
            ) : page === "Settings" ? (
              <Settings />
            ) : (
              <Loginpg />
            )}
          </div>

          {successful.statuscode === 1 ? (
            <LoadVeriy type="spin" text={successful.message} />
          ) : successful.statuscode === 2 ? (
            <Error
              closeOverlay={handleClose}
              message={successful.message}
              isTimeout={false}
              icon={successful.type}
            />
          ) : successful.statuscode === 3 ? (
            <Error
              message={successful.message}
              isTimeout={true}
              icon={successful.type}
            />
          ) : (
            <></>
          )}

          {addDirector && (
            <AddDirectors
              closeOverlay={handleCloseoverlay}
              setExpire={handlExpiration}
            />
          )}
          {addRegion && <AddRegions closeOverlay={handleCloseoverlay} />}
          {addProm && <AddPromo closeOverlay={handleCloseoverlay} />}
          {treatConfirm && (
            <Confirmpg
              setloading={handleNotice}
              closeOverlay={handleCloseoverlay}
            />
          )}
          {canlogin && (
            <Confirms
              closeOverlay={handleCloseoverlay}
              claimtreats={treatemail}
            />
          )}
          {expiration && (
            <Error
              message={"Session Expired Re-Login"}
              isTimeout={true}
              icon="3"
            />
          )}
          {notice === "newallocation" ? (
            <Allocate setloading={handleNotice} />
          ) : notice === "newbudget" ? (
            <Budgetfinance setloading={handleNotice} />
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import "./style.css";
import axios from "axios";
import { CgCloseO } from "react-icons/cg";
import Error from "./Error";
import LoadVeriy from "./Loading/LoadVeriy";
import { useCookies } from "react-cookie";
import { API } from "../server";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { Store } from "../Redux/store";

const Confirms = ({ closeOverlay, claimtreats }) => {
  // console.log(claimtreats);
  const [cookies] = useCookies(["Token"]);
  const [confirmresponse, setConfirmresponse] = useState(0);
  const [confirmload, setConfirmload] = useState(false);
  const [expiration, setExpiration] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [claimtreat, setClaimtreat] = useState({
    emails: "",
    bnwregions: "",
  });

  useEffect(() => {
    setClaimtreat({ emails: claimtreats.mail, bnwregions: claimtreats.reg });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeConfirm = () => {
    closeOverlay();
  };

  const handleActivate = async () => {
    setConfirmload(true);
    try {
      var config = {
        method: "POST",
        url: `${API}/admin/updateAgent`,
        data: {
          email: claimtreat.emails,
          // region: claimtreat.bnwregions,
          canLogin: true,
        },
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        // console.log(response.data.data);
        if (response.data.success === true) {
          // Store.dispatch(getAllLevy(res.data.levy));
          setConfirmload(false);
          toast.success(response.data.message);
          handleClose()
        }
      });
    } catch (e) {
      if (e.message.includes("401")) {
        toast.error(e?.res?.data);
        setExpiration(true);
      } else {
        setExpiration(false);
        setConfirmload(false);
        toast.error(e?.res?.data);
      }
    }
  };

  const handleCloseOverlay = () => {
    setConfirmresponse(0);
    closeConfirm();
  };

  const handleDecline = async () => {
    setConfirmload(true);
    try {
      var config = {
        method: "POST",
        url: `${API}/admin/updateAgent`,
        data: {
          email: claimtreat.emails,
          // region: claimtreat.bnwregions,
          canLogin: false,
        },
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        // console.log(response.data.data);
        if (response.data.success === true) {
          setConfirmload(false);
          toast.success(response.data.message);
          handleClose()
        }
      });
    } catch (e) {
      if (e.message.includes("401")) {
        // // console.log(`Error `)
        setConfirmresponse(3);
        setExpiration(true);
      } else {
        setConfirmload(false);
        setExpiration(false);
        toast.error(e?.res?.data);
      }
    }
  };

  const handleClose = () => {
    setConfirmresponse(0);
    closeConfirm();
  };

  return (
    <div className="bnw-confirm-pg">
      {confirmload ? (
        <LoadVeriy type="spin" text="Processing..." />
      ) : (
        <div className="w-[50%] min-w-[280px] h-[250px] bg-[white] p-3 flex items-center flex-col gap-3">
          <div className="w-full flex items-center justify-end">
            <CgCloseO
              onClick={closeConfirm}
              size={28}
              className="cursor-pointer"
            />
          </div>

          <span className="text-[15px] font-[800] underline underline-offset-4">
            GRANT LOGIN ACCESS?
          </span>

          <span className="text-[14px]">
            {claimtreats.status === 1
              ? "Are you sure you want to activate this Client ?"
              : "Are you sure you want to deactivate this Client ?"}
          </span>

          <div className="flex justify-around items-center w-full mt-5">
            <div className="relative bg-[blue] h-fit flex items-center justify-center">
              <button
                disabled={loading ? true : false}
                onClick={claimtreats.status === 1 ? handleActivate : handleDecline}
                className="bg-[green] text-[white] flex justify-center items-center text-[12px] font-[500] w-[50px] p-2 cursor-pointer"
              >
                YES
              </button>
              {uploading && (
                <ReactLoading
                  className="absolute top-[0%] z-10"
                  type="spin"
                  color="white"
                  height={"70%"}
                  width={"70%"}
                />
              )}
            </div>

            <div className="relative h-fit flex items-center justify-center">
              <button
                onClick={handleCloseOverlay}
                className="bg-[red] text-[white] flex justify-center items-center text-[12px] font-[500] w-[50px] p-2 cursor-pointer"
              >
                NO
              </button>
            </div>
          </div>
        </div>
      )}
      {confirmresponse === 2 ? (
        <Error
          closeOverlay={handleClose}
          message={"Claims Declined"}
          isTimeout={false}
          icon="2"
        />
      ) : confirmresponse === 3 ? (
        expiration ? (
          <Error
            message={"Session Expired Re-Login"}
            isTimeout={true}
            icon="3"
          />
        ) : (
          <Error
            closeOverlay={handleClose}
            message={"Connection Error"}
            isTimeout={false}
            icon="2"
          />
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default Confirms;

import React, { useState, useEffect } from "react";
import Boardcard from "../Boardcard/Boardcard";
import "./Cards.css";

const Pocketbalance = ({
  backcolor,
  strokecolor,
  text,
  value,
  maximum,
  currency,
  gradient,
  piebackcolor,
}) => {
  const [PercentValue, setPercentValue] = useState();
  const [claimCount, setClaimCount] = useState();

  useEffect(() => {
    SetActiveClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SetActiveClaims = () => {
    setClaimCount(value); // Value of Claim Count coming from the DB
    setPercentValue((claimCount / maximum) * 100);
  };
  return (
    <div
      className="bnw-claimcard-container"
      style={{ backgroundColor: backcolor }}
    >
      <span className="text-[13px] font-[800] text-[#323C4D]">{text}</span>
      <Boardcard
        val={value}
        maxV={maximum}
        minV={0}
        backgrd={strokecolor}
        currency={currency}
        words={1}
      />
    </div>
  );
};

export default Pocketbalance;

import React, {useState} from 'react'
import { IoChevronDown } from "react-icons/io5";
import './Singleselect.css'

const Singleselect = ({selectedSocVal, placeholder, socialjs}) => {
    const [socialvalue, setSocialvalue] = useState("")
    const [social, setSocial] = useState(false)

    const handleSocialFocus = () =>{
        setSocial(!social)
    }
    
    const handleSocialClick = (e, incoming) =>{
        setSocialvalue(incoming.soc)
        setSocial(!social)
        selectedSocVal(incoming.soc)
    }
    
  return (
    <div className='single-selectt-container'>
        <input value={socialvalue} onFocus={handleSocialFocus} placeholder={placeholder} id="bnw-firnamee-box" className='text-[13px] p-2 rounded-[3px]'/>
            <IoChevronDown id="chevron-down" onClick={handleSocialFocus}/>
            {social && <div className='bnw-regionn-drpdwn'>{socialjs.map((item, key) => (
                <span onClick={e => handleSocialClick(e, {soc: item.value})} key={key}>{item.value}</span>
            ))}
            </div>}
    </div>
  )
}

export default Singleselect
// capture button click
export const captureButtonClick = (currentpage) => ({
  type: "CAPTURE_Sidebar_Tab",
  payload: currentpage,
});

export const captureSelectedMonth = (month) => ({
  type: "Selected_Month",
  payload: month,
});

export const captureSelectedYear = (year) => ({
  type: "Selected_Year",
  payload: year,
});

export const captureTopPerforming = (topperfclick) => ({
  type: "Top_performing_agency",
  payload: topperfclick,
});

export const captureModalOverlay = (newmodal) => ({
  type: "Add_New_Modal",
  payload: newmodal,
});



const pointBreakdwonScript = (inputdata, Regionsjson, exchange_rate) => {
  const result = [];

  Object.keys(inputdata).forEach((country) => {
    const countryData = inputdata[country];

    if (countryData) {
      const activePoints = countryData.reduce(
        (sum, entry) => sum + parseInt(entry.active_points),
        0
      );

      const unflownTickets = countryData.reduce(
        (sum, entry) => sum + parseInt(entry.unflown_tickets),
        0
      );

      const invalidPoints = countryData.reduce(
        (sum, entry) => sum + parseInt(entry.invalid_points),
        0
      );

      const activeClaims = countryData.reduce(
        (sum, entry) => sum + parseInt(entry.active_claims),
        0
      );

      const approvedClaims = countryData.reduce(
        (sum, entry) => sum + parseInt(entry.approved_claims),
        0
      );

      const cancelledClaims = countryData.reduce(
        (sum, entry) => sum + parseInt(entry.cancelled_claims),
        0
      );

      const totalPoints = countryData.reduce(
        (sum, entry) => sum + parseInt(entry.total_points),
        0
      );

      const regionCode = Regionsjson.find(
        (region) => region.label === country
      )?.value;

      // find the exchange rate whose region_code is equal to regionCode
      const exchangeRateData = exchange_rate?.find(
        (rate) => rate.region_code === regionCode
      );

      const amountPerLocalCurrency = exchangeRateData
        ? exchangeRateData.amountPerLocalCurrency
        : 0;

      const amountPerDollar = exchangeRateData
        ? exchangeRateData.amountPerDollar
        : 0;

      result.push({
        country,
        region_code: regionCode,
        active_points: activePoints,
        unflown_tickets: unflownTickets,
        invalid_points: invalidPoints,
        amountPerLocalCurrency: amountPerLocalCurrency,
        amountPerDollar: amountPerDollar,
        total_points: totalPoints,
        active_claims: activeClaims,
        approved_claims: approvedClaims,
        cancelled_claims: cancelledClaims,
      });
    }
  });

  return result;
};

export default pointBreakdwonScript;

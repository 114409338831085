export const SeerbitDataID = [
    {
        id: 1,
        pocketId: 'SBP0000135',
        region: 'CI'
    },
    {
        id: 2,
        pocketId: 'SBP0000135',
        region: 'ML'
    },
    {
        id: 3,
        pocketId: 'SBP0000135',
        region: 'GH'
    },
    {
        id: 4,
        pocketId: 'SBP0000135',
        region: 'SN'
    },
]
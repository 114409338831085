import React, { useState, useEffect } from "react";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import { IoStatsChartOutline } from "react-icons/io5";
import { CSVLink } from "react-csv";
import { FiUpload } from "react-icons/fi";
import { useSelector } from "react-redux";
import agencyBreakdwonScript from "../Component/Scripts/agencybreakdownscript";
import { GoArrowLeft } from "react-icons/go";
import { Store } from "../Redux/store";
import { captureTopPerforming } from "../Redux/Actions/buttonClick";
import agencybreakdownbyperformance from "../Component/Scripts/agencybreakdownbyperformance";
import toperformingRegions from "../Component/Scripts/topfiveregionsbreakdown";
import Regionsjson from "../Component/Cards/regionsjson";
import TPRJson from "../Component/Cards/TPRJson";
import NumberFormat from "react-number-format";

const Topperformingregions = () => {
  const [topperformer, setTopperformer] = useState([]);
  const { pointbreakdown } = useSelector((state) => state.datareponse);

  let allocationData = agencyBreakdwonScript(pointbreakdown);

  allocationData = toperformingRegions(allocationData, Regionsjson, TPRJson);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setTopperformer(allocationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [exportpro, setExportpro] = useState(false);
  const [gobackbut, setGobackbut] = useState(false);

  const csvHeaders = [
    { label: "Id", key: "id" },
    { label: "Country", key: "region" },
    { label: "Flown Ticket", key: "flown_ticket" },
    { label: "Unflown Ticket", key: "unflown_ticket" },
    { label: "Uncaptured Ticket", key: "uncaptured_ticket" },
    { label: "Total Ticket", key: "total_ticket" },
  ];

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const handleExportCSV = () => {};

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerpage = 10; //Number of items to be displayed per page
  const pageCount = Math.ceil(topperformer.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const handleCountryClick = () => {};

  const handle_Hover = () => {
    setGobackbut(!gobackbut);
  };

  const handleBackclick = () => {
    Store.dispatch(captureTopPerforming(0));
  };

  const CumPoints = topperformer.reduce(
    (accumulator, currentValue) =>
      parseInt(accumulator) + parseInt(currentValue.total_points),
    0
  );

  const CumActiveClaim = topperformer.reduce(
    (accumulator, currentValue) =>
      parseInt(accumulator) + parseInt(currentValue.active_claims),
    0
  );

  const CumApprovedClaim = topperformer.reduce(
    (accumulator, currentValue) =>
      parseInt(accumulator) + parseInt(currentValue.approved_claims),
    0
  );

  const CumCancelledClaim = topperformer.reduce(
    (accumulator, currentValue) =>
      parseInt(accumulator) + parseInt(currentValue.cancelled_claims),
    0
  );

  const CumActivePoints = topperformer.reduce(
    (accumulator, currentValue) =>
      parseInt(accumulator) + parseInt(currentValue.active_points),
    0
  );

  const CumUnflownTkt = topperformer.reduce(
    (accumulator, currentValue) =>
      parseInt(accumulator) + parseInt(currentValue.unflown_tickets),
    0
  );

  const CumInvalidPoints = topperformer.reduce(
    (accumulator, currentValue) =>
      parseInt(accumulator) + parseInt(currentValue.invalid_points),
    0
  );

  return (
    <div className="w-full h-full z-20 flex flex-col absolute top-0 gap-2 bg-[white]">
      <div className="w-full flex flex-col gap-1">
        <div className="w-full justify-between flex items-center bg-[#e50000] p-1">
          <div className="w-full flex items-center gap-3">
            <span
              className={`p-2 border rounded-full cursor-pointer ${
                gobackbut ? "bg-[#fff]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handle_Hover}
              onMouseLeave={handle_Hover}
              onClick={handleBackclick}
            >
              <GoArrowLeft size={18} color={gobackbut ? "#e50000" : "#fff"} />
            </span>
            <div className="relative flex justify-center items-center">
              <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[13px] font-[600]">
                Regions Performance Analysis
              </span>

              <IoStatsChartOutline size={25} color="#fff" />
            </div>
          </div>

          <CSVLink data={topperformer} headers={csvHeaders}>
            <div
              className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
                exportpro ? "bg-[white]" : "bg-[#e50000]"
              }`}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              onClick={handleExportCSV}
            >
              <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
              <span
                className={`px-2 py-1 cursor-pointer ${
                  exportpro ? "text-[#e50000]" : "text-[#fff]"
                } text-[11px] font-[600]`}
              >
                Export
              </span>
            </div>
          </CSVLink>
        </div>

        <table>
          <thead>
            <tr>
              <th scope="col" className="text-[11px]">
                Active Claims
              </th>
              <th scope="col" className="text-[11px]">
                Approved Claims
              </th>
              <th scope="col" className="text-[11px]">
                Cancelled Claims
              </th>
              <th scope="col" className="text-[11px]">
                Active Points
              </th>
              <th scope="col" className="text-[11px]">
                Invalid Points
              </th>
              <th scope="col" className="text-[11px]">
                Unflown Tickets
              </th>
              <th scope="col" className="text-[11px]">
                Total
              </th>
              <th scope="col" className="text-[11px]">
                Region
              </th>
              {/* <th scope="col">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {topperformer
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((tdata) => (
                <tr
                  className="body-tab-row cursor-pointer"
                  key={tdata.id}
                  onClick={(e) => handleCountryClick(e, tdata.id)}
                >
                  <td data-label="Active Claims" className="text-[11px]">
                    {tdata.active_claims}
                  </td>
                  <td data-label="Approv. Claims" className="text-[11px]">
                    {tdata.approved_claims}
                  </td>
                  <td data-label="Status" className="text-[11px]">
                    {tdata.cancelled_claims}
                  </td>

                  <td data-label="Active Points" className="text-[11px]">
                    {tdata.active_points}
                  </td>
                  <td data-label="Invalid Points" className="text-[11px]">
                    {tdata.invalid_points}
                  </td>
                  <td data-label="Unfloen Tickets" className="text-[11px]">
                    {tdata.unflown_tickets}
                  </td>
                  <td data-label="Total" className="text-[11px]">
                    {tdata.total_points}
                  </td>
                  <td data-label="Region" className="text-[11px]">
                    {tdata.region_label}
                  </td>
                </tr>
              ))}

            <tr className="body-tab-rowws text-[11px]">
              <td data-label="Active Claims">
                <NumberFormat
                  value={CumActiveClaim}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </td>

              <td data-label="Approved Claims">
                <NumberFormat
                  value={CumApprovedClaim}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </td>

              <td data-label="Cancelled Claims">
                <NumberFormat
                  value={CumCancelledClaim}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </td>

              <td data-label="Active Points">
                <NumberFormat
                  value={CumActivePoints}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </td>

              <td data-label="Invalid Points">
                <NumberFormat
                  value={CumInvalidPoints}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </td>

              <td data-label="Unflown Tickets">
                <NumberFormat
                  value={CumUnflownTkt}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </td>

              <td data-label="Total">
                <NumberFormat
                  value={CumPoints}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                />
              </td>
              <td data-label="Local Equv."></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="bnw-table-pgnate-contain">
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default Topperformingregions;

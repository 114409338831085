import React from "react";
import "./Cards.css";
import Travels from "./Travels";
import { useSelector } from "react-redux";
import agencyBreakdwonScript from "../Scripts/agencybreakdownscript";
import toperformingAgencies from "../Scripts/topfiveagencybreakdown";
import { Store } from "../../Redux/store";
import { topPerformingAgencyByFlownTicket } from "../../Redux/Actions/pocketDetails";
import { captureTopPerforming } from "../../Redux/Actions/buttonClick";

const TPA = () => {
  let imageObject = {
    0: "/avaters/1.jpg",
    1: "/avaters/2.jpg",
    2: "/avaters/3.jpg",
    3: "/avaters/4.jpg",
    4: "/avaters/5.jpg",
  };
  const { pointbreakdown, role } = useSelector((state) => state.datareponse);
  // // console.log("Point Breakdown: ", pointbreakdown)
  // The region of the director
  let myregion = "CI";

  let allocationData = agencyBreakdwonScript(pointbreakdown);

  // // console.log(allocationData);

  allocationData = toperformingAgencies(allocationData);
  // Store.dispatch(topPerformingAgencyByFlownTicket(allocationData));

  const handleTopPerforming = () => {
    Store.dispatch(captureTopPerforming(1));
  };

  const handlenoClick = () => {
    
  };

  return (
    <div
      className="bnw-tpacard-container p-[20px] max-h-fit"
      onClick={!role.includes("ADMIN") ? handlenoClick : handleTopPerforming}
    >
      <span id="bnw-tpacard-text" className="text-[13px] font-[800]">
        Top Performing Agencies
      </span>

      {(role === "DIRECTOR"
        ? allocationData.filter((tdata) => tdata.region_code === myregion)
        : allocationData.slice(0, 5)
      ).map((items, key) => (
        <Travels
          key={key}
          imgurl={imageObject[key]}
          agencyname={items.agency_name}
          agentname={items.agent_name}
          percent={items.percentage}
        />
      ))}
    </div>
  );
};

export default TPA;

import React, { useState, useEffect } from "react";
import "./Cards.css";
import Travels from "./Travels";
import TPRJson from "./TPRJson";
import { useSelector } from "react-redux";
import toperformingRegions from "../Scripts/topfiveregionsbreakdown";
import agencyBreakdwonScript from "../Scripts/agencybreakdownscript";
import Regionsjson from "./regionsjson";
import { captureTopPerforming } from "../../Redux/Actions/buttonClick";
import { Store } from "../../Redux/store";

const TPR = () => {
  const { pointbreakdown } = useSelector((state) => state.datareponse);

  let allocationData = agencyBreakdwonScript(pointbreakdown);

  allocationData = toperformingRegions(allocationData, Regionsjson, TPRJson);
  // // console.log(allocationData);
  // Store.dispatch(topPerformingAgencyByFlownTicket(allocationData));

  const handleTopPerforming = () => {
    Store.dispatch(captureTopPerforming(2));
  };

  return (
    <div
      className="bnw-tpregcard-container cursor-pointer p-[20px] h-fit"
      onClick={handleTopPerforming}
    >
      <span id="bnw-tpacard-text" className="text-[13px] font-[800]">
        Top Performing Regions
      </span>
      {allocationData.map((items, key) => (
        <Travels
          key={key}
          imgurl={items.countryFlag}
          agencyname={items.region_label}
          agentname={items.agency_name}
          percent={items.percentage}
        />
      ))}
    </div>
  );
};

export default TPR;

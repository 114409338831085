// export const Liability = (points, exchangeRates) => {
//   // Create a map to store summed values for each region_code
//   const summedValues = {};

//   // Calculate summed values for each region_code
//   points.forEach((point) => {
//     if (!summedValues[point.region_code]) {
//       summedValues[point.region_code] = {
//         summed: 0,
//         region_code: point.region_code,
//       };
//     }
//     summedValues[point.region_code].summed++;
//   });

//   console.log(summedValues);

//   // Calculate equivalent_sum and equivalent_sum_usd
//   const resultArray = Object.values(summedValues).map((summedValue) => {
//     const exchangeRate = exchangeRates?.find(
//       (rate) => rate.region_code === summedValue.region_code
//     );
//     const equivalentSum =
//       summedValue.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
//     const equivalentSumUSD =
//       equivalentSum / (exchangeRate ? exchangeRate.amountPerLocalCurrency : 1);

//     return {
//       summed: summedValue.summed,
//       equivalent_sum: equivalentSum,
//       equivalent_sum_usd: parseFloat(equivalentSumUSD.toFixed(1)),
//       region_code: summedValue.region_code,
//     };
//   });

//   return resultArray;
// };

// export const Liability = (points, claimsdata, exchangeRates) => {
//   // Step 1: Count occurrences of each region_code in claimsdata
//   const claimsOccurrence = claimsdata.reduce((acc, claim) => {
//     acc[claim.region_code] = (acc[claim.region_code] || 0) + 1;
//     return acc;
//   }, {});

//   // Step 2: Count occurrences of each region_code in points
//   const pointsOccurrence = points.reduce((acc, point) => {
//     acc[point.region_code] = (acc[point.region_code] || 0) + 1;
//     return acc;
//   }, {});

//   // Step 3: Calculate the difference between pointsOccurrence and claimsOccurrence
//   const finalOccurrence = Object.keys(pointsOccurrence).map((region_code) => ({
//     region_code,
//     summed:
//       (pointsOccurrence[region_code] || 0) -
//       (claimsOccurrence[region_code] || 0),
//   }));

//   // Step 4 and 5: Calculate equivalent_sum and equivalent_sum_usd
//   const resultArray = finalOccurrence.map((item) => {
//     const exchangeRate = exchangeRates?.find(
//       (rate) => rate.region_code === item.region_code
//     );
//     const equivalent_sum =
//       item.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
//     const equivalent_sum_usd =
//       equivalent_sum / (exchangeRate ? exchangeRate.amountPerLocalCurrency : 1);
//     return {
//       ...item,
//       equivalent_sum,
//       equivalent_sum_usd,
//     };
//   });

//   return resultArray;
// };

// export const Liability = (points, claimsdata, exchangeRates) => {
//   // Step 1: Sum the "points" of regions that are similar to form a new array
//   const claimsOccurrence = claimsdata.reduce((acc, claim) => {
//     acc[claim.region_code] = (acc[claim.region_code] || 0) + claim.points;
//     return acc;
//   }, {});

//   // Step 2: Sum the "points" of regions in points data
//   const pointsOccurrence = points.reduce((acc, point) => {
//     acc[point.region_code] = (acc[point.region_code] || 0) + point.points;
//     return acc;
//   }, {});

//   // Step 3: Calculate the difference between pointsOccurrence and claimsOccurrence
//   const finalOccurrence = Object.keys(pointsOccurrence).map((region_code) => ({
//     region_code,
//     summed:
//       (pointsOccurrence[region_code] || 0) -
//       (claimsOccurrence[region_code] || 0),
//   }));

//   // Step 4 and 5: Calculate equivalent_sum and equivalent_sum_usd
//   const resultArray = finalOccurrence.map((item) => {
//     const exchangeRate = exchangeRates.find(
//       (rate) => rate.region_code === item.region_code
//     );
//     const equivalent_sum =
//       item.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
//     const equivalent_sum_usd =
//       equivalent_sum /
//       (exchangeRate ? exchangeRate.amount_per_local_currency : 1);
//     return {
//       ...item,
//       equivalent_sum,
//       equivalent_sum_usd,
//     };
//   });

//   return resultArray;
// };

export const Liability = (points, claimsdata, exchangeRates) => {
  // Step 1: Sum the "points" of regions that are similar
  const claimsOccurrence = claimsdata.reduce((acc, claim) => {
    acc[claim.region_code] = (acc[claim.region_code] || 0) + claim.points;
    return acc;
  }, {});

  // Step 2: Sum the "points" of regions that are similar from the "points" array
  const pointsOccurrence = points.reduce((acc, point) => {
    acc[point.region_code] = (acc[point.region_code] || 0) + 1;
    return acc;
  }, {});

  // Step 3: Calculate the difference between pointsOccurrence and claimsOccurrence
  const finalOccurrence = Object.keys(pointsOccurrence).map((region_code) => ({
    region_code,
    summed:
      (pointsOccurrence[region_code] || 0) -
      (claimsOccurrence[region_code] || 0),
  }));

  // Step 4: Calculate equivalent_sum and equivalent_sum_usd
  const resultArray = finalOccurrence.map((item) => {
    const exchangeRate = exchangeRates?.find(
      (rate) => rate.region_code === item.region_code
    );
    const equivalent_sum =
      item.summed * (exchangeRate ? exchangeRate.amountPerDollar : 0);
    const equivalent_sum_usd =
      equivalent_sum / (exchangeRate ? exchangeRate.amountPerLocalCurrency : 1);
    return {
      ...item,
      equivalent_sum,
      equivalent_sum_usd,
    };
  });

  return resultArray;
};

import React, { useState, useEffect } from "react";
import ButtonPagenate from "../../Component/ButtonPagenate/ButtonPagenate";
import Toggle from "../../Component/Togglebutton/Togglebutton";
import "../page.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import SLoader from "../../Component/Loading/SigninLoader";
import ClaimsJsn from "./claimsJson";
import Pocketbalance from "../../Component/Cards/Pocketbalance";
import { useSelector } from "react-redux";
import { Store } from "../../Redux/store";
import { treatClaims } from "../../Redux/Actions/treatClaims";
import { API } from "../../server";
import currencyUnit from "./currencyUnit";
// import Error from '../Component/Error'
// import TableDataJson from './tabledatajson'

const Claims = ({
  setloading,
  setreat,
  setExpire
}) => {
  const {role} = useSelector((state) => state.datareponse);
  const { homepagedata, exchangerate } = useSelector((state) => state.datareponse);

  let allocationSummation =  homepagedata.allocation_summation

  const [cookies] = useCookies(["Token"]);
  const [incomingdata, setIncomingdata] = useState([]);
  const [incomingpend, setIncomingpend] = useState([]);
  const [treatConfirm, setTreatConfirm] = useState(true);
  const [filtertab, setFiltertab] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const {criteria, agencyname} = useSelector((state) => state.searchdata.searchtext);

  //user1, admin
  useEffect(() => {
    FetchAllClaims();
    FetchPocketBalance();
    // FetchPendClaims()
    // FetchApprClaims()
    // FetchCanClaims()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterClaims = (filter_value) => {
    filter_value = filter_value - 1;
    let filteredClaims = incomingdata.filter(
      (object_data) => object_data.claim_status === filter_value
    );
    setIncomingpend(filteredClaims);
  };

  const FetchAllClaims = async () => {
    try {
      setloading({
        statuscode: 1,
        message: "Fetching Claims",
        type: 1,
      });

      var config = {
        method: "GET",
        url: `${API}/claims`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          setloading({
            statuscode: 0,
            message: "",
            type: 0,
          });
          setIncomingdata(
            response.data.data === null ? ClaimsJsn : response.data.data
          );
        }
      });
    } catch (e) {
      // If response returns status of 401. then the session has expired, user have to re-login Session Time set to 1hr
      if (e.response.status === 401) {
        setloading({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        setloading({
          statuscode: 2,
          message: e.message,
          type: 2,
        });
      }
    }
  };

  const FetchPocketBalance = () => {};

  // const FetchPendClaims = async () =>{
  //   // // console.log(`Token: ${cookies.Token}`)
  //   setLoading(true)
  //   try{
  //     var config = {
  //       method: 'GET',
  //       url: `${API}/claims/pending`,
  //       headers: {
  //         Authorization: "Bearer " + cookies.Token
  //       }
  //     };

  //     await axios(config).then((response) => {
  //       if(response.data.success === true){
  //         setIncomingpend(response.data.data === null ? ClaimsJsn : response.data.data)
  //         setLoading(false)
  //       }
  //     });
  //   } catch(e){
  //     // If response returns status of 401. then the session has expired, user have to re-login Session Time set to 1hr
  //     if(e.message.includes('401')){
  //       // // console.log(`Error `)
  //       setLoading(false)
  //       setExpire()
  //     }
  //   }
  // }

  // const FetchApprClaims = async () =>{
  //   // // console.log(`Token: ${cookies.Token}`)
  //   setLoading(true)
  //   try{
  //     var config = {
  //       method: 'GET',
  //       url: `${API}/claims/approved`,
  //       headers: {
  //         Authorization: "Bearer " + cookies.Token
  //       }
  //     };

  //     await axios(config).then((response) => {
  //     // // console.log(response.data.data);
  //     if(response.data.success === true){
  //       setIncomingAppr(response.data.data === null ? ClaimsJsn : response.data.data)
  //       setLoading(false)
  //     } if(response.status === 401){
  //       // // console.log(" Connection Error")
  //     }});
  //   } catch(e){
  //     // If response returns status of 401. then the session has expired, user have to re-login Session Time set to 1hr
  //     if(e.message.includes('401')){
  //       // // console.log(`Error `)
  //       setLoading(false)
  //       setExpire()
  //     }
  //   }
  // }

  // const FetchCanClaims = async () =>{
  //   // // console.log(`Token: ${cookies.Token}`)
  //   setLoading(true)
  //   try{
  //     var config = {
  //       method: 'GET',
  //       url: `${API}/claims/cancelled`,
  //       headers: {
  //         Authorization: "Bearer " + cookies.Token
  //       }
  //     };

  //     await axios(config).then((response) => {
  //     // // console.log(response.data.data);
  //     if(response.data.success === true){
  //       setIncomingCanc(response.data.data === null ? ClaimsJsn : response.data.data)
  //       setLoading(false)
  //     } if(response.status === 401){
  //       // // console.log(" Connection Error")
  //     }});
  //   } catch(e){
  //     // If response returns status of 401. then the session has expired, user have to re-login Session Time set to 1hr
  //     if(e.message.includes('401')){
  //       // // console.log(`Error `)
  //       setLoading(false)
  //       setExpire()
  //     }
  //   }
  // }

  const itemsPerpage = 20; //Number of items to be displayed per page

  const pageCount = Math.ceil(
    (incomingdata === null ? 0 : incomingdata.length) / itemsPerpage
  );
  const pagesVisited = pageNumber * itemsPerpage;

  // Pending
  const pageCount_pend = Math.ceil(
    (incomingpend === null ? 0 : incomingpend.length) / itemsPerpage
  );
  const pagesVisited_pend = pageNumber * itemsPerpage;

  // Approve
  const pageCount_appr = Math.ceil(
    (incomingpend === null ? 0 : incomingpend.length) / itemsPerpage
  );
  const pagesVisited_appr = pageNumber * itemsPerpage;

  // Canceled
  const pageCount_cancl = Math.ceil(
    (incomingpend === null ? 0 : incomingpend.length) / itemsPerpage
  );
  const pagesVisited_cancl = pageNumber * itemsPerpage;

  const Treated = (e, incomingobjt) => {
    setTreatConfirm(!treatConfirm);
    setreat(incomingobjt);
    Store.dispatch(treatClaims(incomingobjt))
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const statusObj = {
    1: "Approved",
    2: "Pending",
    3: "Declined",
    4: "",
  };

  const handleFilterClick = (e) => {
    if (e.target.id === "all") {
      FetchAllClaims();
      setFiltertab(0);
    }

    if (e.target.id === "pend") {
      // FetchPendClaims()
      filterClaims(1);
      setFiltertab(1);
    }

    if (e.target.id === "appr") {
      // FetchApprClaims()
      filterClaims(2);
      setFiltertab(2);
    }

    if (e.target.id === "decl") {
      // FetchCanClaims()
      filterClaims(3);
      setFiltertab(3);
    }
  };

  var newdataset = (
    filtertab === 0
      ? incomingdata
      : filtertab === 1
      ? incomingpend
      : filtertab === 2
      ? incomingpend
      : incomingpend
  ).filter((filteredItem) => {
    if (criteria === "" && agencyname === "") {
      return filteredItem;
    } else if (criteria !== "" && agencyname === "") {
      return filteredItem;
    } else if (criteria === "" && agencyname !== "") {
      return filteredItem;
    } else if (criteria !== "" && agencyname !== "") {
      if (criteria === "User Id") {
        if (filteredItem.id.toString().includes(agencyname.toLowerCase())) {
          return filteredItem;
        }
      } else if (criteria === "Name") {
        if (
          filteredItem.first_name
            .toLowerCase()
            .includes(agencyname.toLowerCase())
        ) {
          return filteredItem;
        }
      } else {
        return filteredItem;
      }
    }
  });

  return (
    <div className="w-full flex flex-col gap-5 px-4">
      <Pocketbalance
        currency={currencyUnit.find((item) => item.region_code === exchangerate.region_code).symbol}
        backcolor="#FFF"
        text="Wallet Balance"
        value={Object.values(allocationSummation)[0] * exchangerate.amountPerLocalCurrency}
        maximum="200000"
        gradient="#FF8855"
        piebackcolor="#FFC8B0"
      />

      <div className="bnw-claim-container">
        <div className="bnw-filter-tab-cntainer">
          <div id="all" className="bnw-fcc tab1 text-[13px]" onClick={handleFilterClick}>
            All
          </div>
          <div id="pend" className="bnw-fcc tab3 text-[13px]" onClick={handleFilterClick}>
            Pending
          </div>
          <div id="appr" className="bnw-fcc tab2 text-[13px]" onClick={handleFilterClick}>
            Approved
          </div>
          <div id="decl" className="bnw-fcc tab4 text-[13px]" onClick={handleFilterClick}>
            Declined
          </div>
        </div>

        {filtertab === 0 ? (
          <div className="bnw-claims-table-container">
            <table className="bnw-claims-table">
              <thead id="bnw-claims-table-head">
                <tr>
                  <th className="text-[13px]">Approve</th>
                  <th className="text-[13px]">Status</th>
                  <th className="text-[13px]">User ID</th>
                  <th className="text-[13px]">Name</th>
                  <th className="text-[13px]">Promo ID</th>
                  <th className="text-[13px]">Points</th>
                  <th className="text-[13px]">Point Eqv.</th>
                  {role === "ADMIN" ? <td>Modified By</td> : ""}
                </tr>
              </thead>
              <tbody>
                {newdataset
                  .reverse()
                  .slice(pagesVisited, pagesVisited + itemsPerpage)
                  .map((item, key) => (
                    <tr key={key}>
                      {/* Note: Treated: 1 and Approved: 1 === Approved
                        Treated: 0 and Approved: 0 === Pending
                        Treated: 1 and Approved: 0 === Declined */}
                      <td className="text-[11px]">
                        {item.claim_status !== "" ? (
                          <Toggle
                            setState={(e) =>
                              Treated(e, {
                                cid: item.id,
                                aclm: item.claim_status,
                              })
                            }
                            approve={item.claim_status}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className="text-[11px]">
                        {item.claim_status !== "" ? (
                          <div
                            id={
                              item.claim_status === 0
                                ? "bnw-tab-status_1"
                                : item.claim_status === 1
                                ? "bnw-tab-status"
                                : "bnw-tab-status_2"
                            }
                          >
                            {item.claim_status === 0
                              ? statusObj[2]
                              : item.claim_status === 1
                              ? statusObj[1]
                              : item.claim_status === 2
                              ? statusObj[3]
                              : statusObj[4]}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className="text-[11px]">{item.id}</td>
                      <td className="text-[11px]">{item.first_name + " " + item.last_name}</td>
                      <td className="text-[11px]">{item.promo_id}</td>
                      <td className="text-[11px]">{item.points}</td>
                      <td className="text-[11px]">{item.points * exchangerate.amountPerDollar}</td>
                      {role === "ADMIN" ? <td className="text-[11px]">{item.modby}</td> : ""}
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="bnw-table-pgnate-contain">
              <ButtonPagenate
                updatedPageNum={newPagenum}
                pageCount={pageCount}
                pageNumber={pageNumber}
                previousLabel="Prev"
                nextLabel="Next"
              />
            </div>
          </div>
        ) : filtertab === 1 ? (
          <div className="bnw-claims-table-container">
            <table className="bnw-claims-table">
              <thead id="bnw-claims-table-head">
                <tr>
                  <td>Approve</td>
                  <td>Status</td>
                  <td>User ID</td>
                  <td>Name</td>
                  <td>Promo ID</td>
                  <td>Points</td>
                  <td>Point Eqv.</td>
                  {role === "ADMIN" ? <td>Modified By</td> : ""}
                </tr>
              </thead>
              <tbody>
                {newdataset
                  .reverse()
                  .slice(pagesVisited_pend, pagesVisited_pend + itemsPerpage)
                  .map((item, key) => (
                    <tr key={key}>
                      <td>
                        {item.claim_status !== "" ? (
                          <Toggle
                            setState={(e) =>
                              Treated(e, {
                                cid: item.id,
                                aclm: item.claim_status,
                              })
                            }
                            approve={item.claim_status}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {item.claim_status !== "" ? (
                          <div
                            id={
                              item.claim_status === 0
                                ? "bnw-tab-status_1"
                                : item.claim_status === 1
                                ? "bnw-tab-status"
                                : "bnw-tab-status_2"
                            }
                          >
                            {item.claim_status === 0
                              ? statusObj[2]
                              : item.claim_status === 1
                              ? statusObj[1]
                              : item.claim_status === 2
                              ? statusObj[3]
                              : statusObj[4]}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>{item.id}</td>
                      <td>{item.first_name + " " + item.last_name}</td>
                      <td>{item.promo_id}</td>
                      <td>{item.points}</td>
                      <td>{item.points_equivalent}</td>
                      {role === "ADMIN" ? <td>{item.modby}</td> : ""}
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="bnw-table-pgnate-contain">
              <ButtonPagenate
                updatedPageNum={newPagenum}
                pageCount={pageCount_pend}
                pageNumber={pageNumber}
                previousLabel="Prev"
                nextLabel="Next"
              />
            </div>
          </div>
        ) : filtertab === 2 ? (
          <div className="bnw-claims-table-container">
            <table className="bnw-claims-table">
              <thead id="bnw-claims-table-head">
                <tr>
                  <td>Approve</td>
                  <td>Status</td>
                  <td>User ID</td>
                  <td>Name</td>
                  <td>Promo ID</td>
                  <td>Points</td>
                  <td>Point Eqv.</td>
                  {role === "ADMIN" ? <td>Modified By</td> : ""}
                </tr>
              </thead>
              <tbody>
                {newdataset
                  .reverse()
                  .slice(pagesVisited_appr, pagesVisited_appr + itemsPerpage)
                  .map((item, key) => (
                    <tr key={key}>
                      <td>
                        {item.claim_status !== "" ? (
                          <Toggle
                            setState={(e) =>
                              Treated(e, {
                                cid: item.id,
                                aclm: item.claim_status,
                              })
                            }
                            approve={item.claim_status}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {item.claim_status !== "" ? (
                          <div
                            id={
                              item.claim_status === 0
                                ? "bnw-tab-status_1"
                                : item.claim_status === 1
                                ? "bnw-tab-status"
                                : "bnw-tab-status_2"
                            }
                          >
                            {item.claim_status === 0
                              ? statusObj[2]
                              : item.claim_status === 1
                              ? statusObj[1]
                              : item.claim_status === 2
                              ? statusObj[3]
                              : statusObj[4]}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>{item.id}</td>
                      <td>{item.first_name + " " + item.last_name}</td>
                      <td>{item.promo_id}</td>
                      <td>{item.points}</td>
                      <td>{item.points_equivalent}</td>
                      {role === "ADMIN" ? <td>{item.modby}</td> : ""}
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="bnw-table-pgnate-contain">
              <ButtonPagenate
                updatedPageNum={newPagenum}
                pageCount={pageCount_appr}
                pageNumber={pageNumber}
                previousLabel="Prev"
                nextLabel="Next"
              />
            </div>
          </div>
        ) : filtertab === 3 ? (
          <div className="bnw-claims-table-container">
            <table className="bnw-claims-table">
              <thead id="bnw-claims-table-head">
                <tr>
                  <td>Approve</td>
                  <td>Status</td>
                  <td>User ID</td>
                  <td>Name</td>
                  <td>Promo ID</td>
                  <td>Points</td>
                  <td>Point Eqv.</td>
                  {role === "ADMIN" ? <td>Modified By</td> : ""}
                </tr>
              </thead>
              <tbody>
                {newdataset
                  .reverse()
                  .slice(pagesVisited_cancl, pagesVisited_cancl + itemsPerpage)
                  .map((item, key) => (
                    <tr key={key}>
                      <td>
                        {item.claim_status !== "" ? (
                          <Toggle
                            setState={(e) =>
                              Treated(e, {
                                cid: item.id,
                                aclm: item.claim_status,
                              })
                            }
                            approve={item.claim_status}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {item.claim_status !== "" ? (
                          <div
                            id={
                              item.claim_status === 0
                                ? "bnw-tab-status_1"
                                : item.claim_status === 1
                                ? "bnw-tab-status"
                                : "bnw-tab-status_2"
                            }
                          >
                            {item.claim_status === 0
                              ? statusObj[2]
                              : item.claim_status === 1
                              ? statusObj[1]
                              : item.claim_status === 2
                              ? statusObj[3]
                              : statusObj[4]}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>{item.id}</td>
                      <td>{item.first_name + " " + item.last_name}</td>
                      <td>{item.promo_id}</td>
                      <td>{item.points}</td>
                      <td>{item.points_equivalent}</td>
                      {role === "ADMIN" ? <td>{item.modby}</td> : ""}
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="bnw-table-pgnate-contain">
              <ButtonPagenate
                updatedPageNum={newPagenum}
                pageCount={pageCount_cancl}
                pageNumber={pageNumber}
                previousLabel="Prev"
                nextLabel="Next"
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Claims;

export const sumAllocationsByMarket = (data) => {
    const result = [];
  
    // Group data by market
    const groupedByMarket = data.reduce((acc, item) => {
      const market = item.market;
      if (!acc[market]) {
        acc[market] = [];
      }
      acc[market].push(item.allocation);
      return acc;
    }, {});
  
    // Sum allocations for each market
    for (const market in groupedByMarket) {
      const sum = groupedByMarket[market].reduce((total, allocation) => total + allocation, 0);
      result.push({
        market: market,
        allocation: sum
      });
    }

    return result;
}
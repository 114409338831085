import React, {useEffect, useState} from "react";
import "./doughnut.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Numberformat from "./Numberformat";
import { useSelector } from "react-redux";
import extractLabels from "../Mergedallocation/countryArray";
import generateChartData from "../Mergedallocation/generateDoughnutdata";
import { sumAllocationsByYearAndMarket } from "../../utils/summedallocationbyyear";

ChartJS.register(ArcElement, Tooltip, Legend);
Chart.register(ChartDataLabels);

const DoughnutChart = ({checkbox,  allocate }) => {
  const [findata, setFindata] = useState({})
  useEffect(() => {
    // console.log("here")
    // setFindata({...TextCenterFunction()})
  }, [checkbox, allocate])
  const currentDate = new Date();
  
  // console.log(checkbox)
  
  let numbvalue = Numberformat(allocate, !checkbox ? "$" : "₦");
  // console.log(numbvalue)

  const { mergedAllocations } = useSelector((state) => state.treatclaim);
  // const allocatedcountries = extractLabels(mergedAllocations);
  // // console.log(allocatedcountries);
  const { marketallocationdata } = useSelector(
    (state) => state.datareponse
  );

  let formattedallocation = sumAllocationsByYearAndMarket(marketallocationdata);

  const chartData = generateChartData(mergedAllocations, formattedallocation[currentDate.getFullYear()]?.TeamII);

  // console.log(chartData);

  let data = {
    labels: chartData.label,
    datasets: [
      {
        label: "Countries",
        data: chartData.data,
        backgroundColor: [
          "#FF66E7",
          "#3F1622",
          "#9B6637",
          "#4F16C7",
          "#33B3C7",
          "#1B96E1",
          "#1C43B7",
          "#8B26E1",
        ],
        borderColor: [
          "#FF66E7",
          "#3F1622",
          "#9B6637",
          "#4F16C7",
          "#33B3C7",
          "#1B96E1",
          "#1C43B7",
          "#8B26E1",
        ],
        borderWidth: 1,
        pointRadius: 1,
      },
    ],
  };

  let options = {
    cutout: 50,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      doughnutlabel: {
        labels: [
          {
            text: "550",
            font: {
              size: 20,
              weight: "bold",
            },
          },
          {
            text: "total",
          },
        ],
      },
      ChartDataLabels,
      datalabels: {
        display: true,
        formatter: (value) => {
          return value + "%";
        },
        borderRadius: 3,
        color: "white",
        font: {
          size: 12,
        },
      },
    },
  };
    
    let textCenter = {
      id: "textCenter",
      beforeDatasetsDraw(chart, args, pluginOptions) {
        const { ctx, data } = chart;
  
        ctx.save();
        ctx.font = "bolder 17px Rajdhani";
        ctx.fillStyle = "grey";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          numbvalue,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y
        );
      },
    };

  //   return textCenter
  // }

  // console.log(findata)
  

  return (
    <div id="chart">
      <Doughnut data={data} options={options} plugins={[textCenter]} />
    </div>
  );
};

export default DoughnutChart;

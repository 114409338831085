import React, { useState, useEffect } from "react";

const MonthDropdown = ({ onChange }) => {
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");

  useEffect(() => {
    // Get the current date to determine the current month
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    // List of all months
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Generate a list of months up to the current month
    const availableMonths = monthNames.slice(0, currentMonth + 1);

    setMonths(availableMonths);
    setSelectedMonth(availableMonths[currentMonth]);

    // Call the onChange function with the initial selected month
    onChange(availableMonths[currentMonth]);
  }, [onChange]);

  const handleMonthChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedMonth(selectedValue);
    onChange(selectedValue);
  };

  return (
    <select
      className="py-1 px-2 outline-none border text-[12px]"
      value={selectedMonth}
      onChange={handleMonthChange}
    >
      {months.map((month, index) => (
        <option key={index} className="text-[12px] px-2 py-1" value={month}>
          {month}
        </option>
      ))}
    </select>
  );
};

export default MonthDropdown;

import React, { useState, useEffect } from "react";
import Boardcard from "../Boardcard/Boardcard";
import "./Cards.css";
import { Store } from "../../Redux/store";
import { captureButtonClick } from "../../Redux/Actions/buttonClick";

const Agency = ({
  backcolor,
  text,
  value,
  maximum,
  gradient,
  piebackcolor,
}) => {
  const [PercentValue, setPercentValue] = useState();
  const [claimCount, setClaimCount] = useState();

  useEffect(() => {
    SetActiveClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SetActiveClaims = () => {
    setClaimCount(value); // Value of Claim Count coming from the DB
    setPercentValue((claimCount / maximum) * 100);
  };

  const handlePageclick = (props) => {
    Store.dispatch(captureButtonClick(props));
  };

  return (
    <div
      className="bnw-locatecard-container shadow max-h-fit"
      style={{ backgroundColor: backcolor }}
      onClick={() => handlePageclick(text)}
    >
      <span
        id="bnw-active-claim-age"
        className="text-[13px] font-[800] text-[#323C4D]"
      >
        {text}
      </span>
      <Boardcard
        val={value}
        maxV={maximum}
        minV={0}
        backgrd="26, 140, 255"
        words={2}
      />
    </div>
  );
};

export default Agency;

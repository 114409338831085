const ClaimsJsn = [
    {
        id: '',
        claim_status: '',
        first_name: "",
        last_name: "",
        points: '',
        points_equivalent: '',
        promo_code: "",
        promo_id: ''
    }
]

export default ClaimsJsn
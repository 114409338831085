import React, { useState, useEffect } from "react";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import { useCookies } from "react-cookie";
import NumberFormat from "react-number-format";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { API } from "../server";
import { FcStatistics } from "react-icons/fc";
import Walletbox from "../Component/Walletbox/Walletbox";
import Walletboxx from "../Component/Walletbox/Walletboxx";
import pointBreakdwonScript from "../Component/Scripts/pointbreakdownscript";
import Regionsjson from "../Component/Cards/regionsjson";
import { IoIosWarning } from "react-icons/io";
import { FaMoneyBillAlt } from "react-icons/fa";
import { IoGiftSharp } from "react-icons/io5";
import { GrDashboard } from "react-icons/gr";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import claimsFunction from "../Component/Scripts/claimsfunction";
import currencyUnit from "./Claims/currencyUnit";
import getCurrentDate from "../Component/DateSelector/Getcurrentdate";
import { sumAllocationsByYearAndMarket } from "../utils/summedallocationbyyear";
import { TbPresentationAnalytics } from "react-icons/tb";
import { addDecimal } from "../Component/Functions/Numberformat";

const Wallet = ({ setloading, nameSearch }) => {
  const criteria = useSelector((state) => state.searchdata.searchtext);
  const currentDate = new Date();

  const [cookies] = useCookies(["Token"]);
  const [incomingdata, setIncomingdata] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [exportpro, setExportpro] = useState(false);
  const [budgetdata, setBudgetdata] = useState([]);
  const [mycheckbox, setMycheckbox] = useState(false);
  const [activetab, setActivetab] = useState(1);
  const [searchdata, setSearchdata] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    FetchAllClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FetchAllClaims = async () => {
    try {
      setloading({
        statuscode: 1,
        message: "Fetching Claims",
        type: 1,
      });

      var config = {
        method: "GET",
        url: `${API}/claims`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          setloading({
            statuscode: 0,
            message: "",
            type: 0,
          });
          setIncomingdata(
            response.data.data === null ? [] : response.data.data
          );
        }
      });
    } catch (e) {
      // If response returns status of 401. then the session has expired, user have to re-login Session Time set to 1hr
      if (e.response.status === 401) {
        setloading({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        setloading({
          statuscode: 2,
          message: e.message,
          type: 2,
        });
      }
    }
  };

  const {
    financialdata,
    regionlist,
    role,
    marketallocationdata,
    pointbreakdown,
    exchangerate,
    transactionhistorydata,
    paystacktransacthistorydata,
  } = useSelector((state) => state.datareponse);

  var filteredpaystacktransactiondata = paystacktransacthistorydata.filter(
    (row) => {
      if (searchdata === "") {
        return row;
      } else {
        // Convert all values to lowercase for case-insensitive search
        const values = Object.values(row).map((value) => {
          // Check if the value is a string before converting to lowercase
          return typeof value === "string" ? value.toLowerCase() : value;
        });
        // Check if any of the values (converted to lowercase) contains the search string
        return values.some((value) => {
          if (typeof value === "string") {
            return value.includes(searchdata.toLowerCase());
          }
          return false; // Ignore non-string values
        });
      }
    }
  );

  let cummAmount = filteredpaystacktransactiondata
    .filter((items) => items.status === "success")
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      0
    );

  let formattedallocation = sumAllocationsByYearAndMarket(marketallocationdata);

  let allocationData = pointBreakdwonScript(
    pointbreakdown,
    Regionsjson,
    exchangerate.data
  );

  let formattedData = claimsFunction(
    incomingdata,
    currencyUnit,
    exchangerate.data
  );

  const [financials, setFinancials] = useState({
    amount_left: 0,
    current_allocation: 0,
    finance_approved_budget: 0,
  });

  useEffect(() => {
    setFinancials({ ...financials, ...financialdata });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialdata]);

  const CumApproved_Claims_USD = formattedData
    .filter(
      (dataelement) =>
        dataelement.claim_status === 1 && dataelement.region_code !== "NG"
    )
    .reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.points_equivalent /
          currentValue.amountPerLocalCurrency !==
        Infinity
          ? currentValue.points_equivalent / currentValue.amountPerLocalCurrency
          : 0),
      0
    )
    .toFixed(1);

  const CumApproved_Claims_NGN = formattedData
    .filter(
      (dataelement) =>
        dataelement.claim_status === 1 && dataelement.region_code === "NG"
    )
    .reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.points_equivalent /
          currentValue.amountPerLocalCurrency !==
        Infinity
          ? currentValue.points_equivalent / currentValue.amountPerLocalCurrency
          : 0),
      0
    )
    .toFixed(1);

  const CumPending_Claims_USD = formattedData
    .filter(
      (dataelement) =>
        dataelement.claim_status === 0 && dataelement.region_code !== "NG"
    )
    .reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.points_equivalent /
          currentValue.amountPerLocalCurrency !==
        Infinity
          ? currentValue.points_equivalent / currentValue.amountPerLocalCurrency
          : 0),
      0
    )
    .toFixed(1);

  const CumPending_Claims_NGN = formattedData
    .filter(
      (dataelement) =>
        dataelement.claim_status === 0 && dataelement.region_code === "NG"
    )
    .reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.points_equivalent /
          currentValue.amountPerLocalCurrency !==
        Infinity
          ? currentValue.points_equivalent / currentValue.amountPerLocalCurrency
          : 0),
      0
    )
    .toFixed(1);
  /**
   * NB: Available Points = pendingclaims + approvedclaims + canceledclaims
   * NB: Active claims = pending claims i.e. claims that have not yet been approved
   * NB: Active Points = flown tickets that u can claim from
   * NB: Invalid Points = system cancelled points
   * NB: Total points = active_points + unflown_tickets
   * **/

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setBudgetdata(allocationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointbreakdown]);

  const filterClaims = (filter_value) => {
    filter_value = filter_value - 1;
    let filteredClaims = incomingdata.filter(
      (object_data) => object_data.claim_status === filter_value
    );
    setIncomingdata(filteredClaims);
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const itemsPerpage = 20; //Number of items to be displayed per page
  const pageCount = Math.ceil(
    (incomingdata === null ? 0 : incomingdata.length) / itemsPerpage
  );

  // pganate for paystack transaction history
  let thirdtabpageCount = Math.ceil(
    (paystacktransacthistorydata === null
      ? 0
      : paystacktransacthistorydata.length) / itemsPerpage
  );
  const pagesVisited = pageNumber * itemsPerpage;

  var newdataset = budgetdata.filter((filteredItem) => {
    if (criteria === "" && nameSearch === "") {
      return filteredItem;
    } else if (criteria !== "" && nameSearch === "") {
      return filteredItem;
    } else if (criteria === "" && nameSearch !== "") {
      return filteredItem;
    } else if (criteria !== "" && nameSearch !== "") {
      if (criteria === "User Id") {
        if (filteredItem.id.toString().includes(nameSearch.toLowerCase())) {
          return filteredItem;
        }
      } else if (criteria === "Name") {
        if (
          filteredItem.first_name
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        ) {
          return filteredItem;
        }
      } else {
        return filteredItem;
      }
    }
  });

  const handleTabChange = (props) => {
    if (props === "tab_1") {
      setActivetab(1);
    } else if (props === "tab_2") {
      setActivetab(2);
    } else if (props === "tab_3") {
      setActivetab(3);
    }
  };

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const handleExportCSV = () => {};

  const csvHeaders = [
    { label: "Id", key: "id" },
    { label: "Country", key: "region" },
    { label: "Flown Ticket", key: "flown_ticket" },
    { label: "Unflown Ticket", key: "unflown_ticket" },
    { label: "Uncaptured Ticket", key: "uncaptured_ticket" },
    { label: "Total Ticket", key: "total_ticket" },
  ];

  const handleCountryClick = async (e, countryid) => {
    // console.log(countryid, e);
  };

  const CumflownTickets = allocationData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.active_points,
    0
  );

  const CumunflownTickets = allocationData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.unflown_tickets,
    0
  );

  const CumuncapturedPoint = allocationData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.invalid_points,
    0
  );

  const CumClaimedPoints = allocationData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.approved_claims,
    0
  );

  const CumunActiveClaimedPoints = allocationData.reduce(
    (accumulator, currentValue) => accumulator + currentValue.active_claims,
    0
  );

  // caimed ticket will still be added to this in future
  const CumTotalPoints = allocationData.reduce(
    (accumulator, currentValue) =>
      accumulator +
      (currentValue.active_points +
        currentValue.invalid_points +
        currentValue.unflown_tickets),
    0
  );

  const CumTicketEquv_LocalCurrency = allocationData.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.active_points * currentValue.amountPerDollar,
    0
  );

  const CumTicketEquv_USD = allocationData
    .reduce(
      (accumulator, currentValue) =>
        accumulator +
        ((currentValue.active_points * currentValue.amountPerDollar) /
          currentValue.amountPerLocalCurrency !==
        Infinity
          ? (currentValue.active_points * currentValue.amountPerDollar) /
            currentValue.amountPerLocalCurrency
          : 0),
      0
    )
    .toFixed(1);

  const handleChange = (e) => {
    setSearchdata(e.target.value);
  };

  return (
    <div className="w-full h-full flex px-1 flex-col gap-2">
      <div className="w-full justify-between flex bg-[#e50000] p-1">
        <div className="flex itmes-center gap-1">
          <div
            className={`relative border flex justify-center items-center ${
              activetab === 1 ? "bg-[#fff9]" : ""
            }`}
            onClick={() => handleTabChange("tab_1")}
          >
            <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
              TICKET RECORD
            </span>

            <FcStatistics size={25} color="#fff" />
          </div>
          <div
            className={`relative border flex justify-center items-center  ${
              activetab === 2 ? "bg-[#fff9]" : ""
            }`}
            onClick={() => handleTabChange("tab_2")}
          >
            <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
              TRANSACTION HISTORY
            </span>

            <TbPresentationAnalytics size={25} color="#fff" />
          </div>

          {role === "DEVELOPER" && (
            <div
              className={`relative border flex justify-center items-center  ${
                activetab === 3 ? "bg-[#fff9]" : ""
              }`}
              onClick={() => handleTabChange("tab_3")}
            >
              <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
                PAYSTACK TRANSACTION HISTORY
              </span>

              <TbPresentationAnalytics size={25} color="#fff" />
            </div>
          )}
        </div>

        <CSVLink data={incomingdata} headers={csvHeaders}>
          <div
            className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
              exportpro ? "bg-[white]" : "bg-[#e50000]"
            }`}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            onClick={handleExportCSV}
          >
            <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
            <span
              className={`px-2 py-1 cursor-pointer ${
                exportpro ? "text-[#e50000]" : "text-[#fff]"
              } text-[12px] font-[600]`}
            >
              Export
            </span>
          </div>
        </CSVLink>
      </div>

      <div className="flex items-center gap-4">
        <div className="flex item-center gap-1">
          <input
            type="checkbox"
            onChange={() => setMycheckbox(!mycheckbox)}
            checked={mycheckbox ? true : false}
            className="cursor-pointer"
          />
          <label
            htmlFor="checkbox"
            className="text-[12px] font-[500] text-[green]"
          >
            Team I
          </label>
        </div>

        <div className="flex item-center gap-1">
          <input
            type="checkbox"
            onChange={() => setMycheckbox(!mycheckbox)}
            checked={!mycheckbox ? true : false}
            className="cursor-pointer"
          />
          <label
            htmlFor="checkbox"
            className="text-[12px] font-[500] text-[red]"
          >
            Team II
          </label>
        </div>
      </div>

      <div className="w-full flex flex-col justify-between gap-2 h-fit">
        <div className="w-full flex justify-between gap-2 flex-wrap h-fit">
          <div className="grow w-[60%] min-w-[500px] h-[150px] bg-[#fff] shadow-md rounded-md py-6 px-3 justify-between flex gap-2">
            <Walletbox
              text1="Paid Claims"
              curr_date={getCurrentDate()}
              amount={
                mycheckbox ? CumApproved_Claims_NGN : CumApproved_Claims_USD
              }
              tooltip_text="All Paid Approved Claims"
              status="Paid"
              mycheckbox={mycheckbox}
            />

            <div className="border mx-2" />

            <Walletbox
              text1="Unpaid Claims"
              curr_date={getCurrentDate()}
              amount={
                mycheckbox ? CumPending_Claims_NGN : CumPending_Claims_USD
              }
              tooltip_text={"All Claims Pending Approvals by directors"}
              status="Pending"
              mycheckbox={mycheckbox}
            />
          </div>

          <Walletboxx
            text1="Pocket Balance"
            curr_date={getCurrentDate()}
            amount={
              formattedallocation[currentDate.getFullYear()]?.TeamII -
              financials.current_allocation
            }
            mycheckbox={mycheckbox}
          />
        </div>

        <div className="w-full h-fit flex gap-2 items-center flex-wrap">
          <Tooltip
            TransitionComponent={Zoom}
            title="Cumulative of unflown tickets / uncaptured tickets"
            followCursor
          >
            <div className="bg-[#fff] rounded-md flex flex-col py-2 px-3 border w-fit cursor-default relative min-w-[170px] gap-1 hover:shadow grow">
              <span className="text-[12px] font-[500]">
                ACCRUED TICKET POINTS
              </span>

              <div className="w-full flex gap-3 items center justify-between">
                <IoIosWarning size={25} color="orange" />

                <span className="text-[orange] text-[18px] font-[500]">
                  <NumberFormat
                    value={CumunflownTickets}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />{" "}
                  /{" "}
                  <NumberFormat
                    value={CumuncapturedPoint}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip
            TransitionComponent={Zoom}
            title="Flown tickets available for claim"
            followCursor
          >
            <div className="bg-[#fff] rounded-md flex flex-col py-2 px-3 border w-fit cursor-default relative min-w-[170px] gap-1 hover:shadow grow">
              <span className="text-[12px] font-[500]">
                FLOWN TCKETS POINTS
              </span>

              <div className="w-full flex gap-5 items center justify-between">
                <FaMoneyBillAlt size={25} color="blue" />

                <span className="text-[blue] text-[18px] font-[500]">
                  <NumberFormat
                    value={CumflownTickets}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip
            TransitionComponent={Zoom}
            title="Cumulative of active claims / approved claims"
            followCursor
          >
            <div className="bg-[#fff] rounded-md flex flex-col py-2 px-3 border w-fit cursor-default relative min-w-[170px] gap-1 hover:shadow grow">
              <span className="text-[12px] font-[500]">
                TOTAL YTD REDEMPTION
              </span>

              <div className="w-full flex gap-5 items center justify-between">
                <IoGiftSharp size={25} color="green" />

                <span className="text-[green] text-[18px] font-[500]">
                  <NumberFormat
                    value={CumunActiveClaimedPoints}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />{" "}
                  /{" "}
                  <NumberFormat
                    value={CumClaimedPoints}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip
            TransitionComponent={Zoom}
            title="Cumulative of inactive points & active points"
            followCursor
          >
            <div className="bg-[#fff] rounded-md flex flex-col py-2 px-3 border w-fit cursor-default relative min-w-[170px] gap-1 hover:shadow grow">
              <span className="text-[12px] font-[500]">TOTAL</span>

              <div className="w-full flex gap-5 items center justify-between">
                <GrDashboard size={25} color="brown" />

                <span className="text-[brown] text-[18px] font-[500]">
                  <NumberFormat
                    value={CumTotalPoints}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </span>
              </div>
            </div>
          </Tooltip>
        </div>
      </div>

      {activetab === 1 ? (
        <div className="bnw-claims-table-container">
          <table>
            <thead>
              <tr>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Country
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Approved Claims
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Flown Ticket
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Local Equv.
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  USD Equv.
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Unflown Ticket
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Invalid Tickets
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Total Tickets
                </th>

                {/* <th scope="col">Action</th> */}
              </tr>
            </thead>

            <tbody>
              {newdataset
                .slice(pagesVisited, pagesVisited + itemsPerpage)
                .map((tdata, index) => (
                  <tr
                    className="body-tab-row cursor-pointer"
                    key={tdata.id}
                    onClick={(e) => handleCountryClick(e, tdata.id)}
                  >
                    <td
                      data-label="ID"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {index + 1}
                    </td>
                    <td
                      data-label="Country"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.country}
                    </td>
                    <td
                      data-label="Approved Tkts."
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.approved_claims}
                    </td>
                    <td
                      data-label="Flown Tkts."
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.active_points}
                    </td>
                    <td
                      data-label="Local Equv."
                      className="text-[11px] whitespace-normal break-words"
                    >
                      <NumberFormat
                        value={tdata.active_points * tdata.amountPerDollar}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={
                          currencyUnit?.find(
                            (element) =>
                              element.region_code === tdata.region_code
                          )?.symbol + " "
                        }
                      />
                    </td>
                    <td
                      data-label="USD Equv."
                      className="text-[11px] whitespace-normal break-words"
                    >
                      <NumberFormat
                        value={parseFloat(
                          (tdata.active_points * tdata.amountPerDollar) /
                            tdata.amountPerLocalCurrency
                        ).toFixed(1)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$ "}
                      />
                    </td>
                    <td
                      data-label="Unflown Tkts."
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.unflown_tickets}
                    </td>
                    <td
                      data-label="Uncaptured Tkts."
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.invalid_points}
                    </td>
                    <td
                      data-label="Total Tkts."
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.active_points +
                        tdata.invalid_points +
                        tdata.unflown_tickets}
                    </td>
                  </tr>
                ))}

              <tr className="body-tab-rowws text-[11px] whitespace-normal break-words">
                <td data-label="">Total</td>
                <td data-label=""></td>
                <td data-label="Approved Claims.">
                  <NumberFormat
                    value={CumClaimedPoints}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </td>
                <td data-label="Flown Tkts.">{CumflownTickets}</td>
                <td data-label="Local Equv."></td>
                <td data-label="USD Equv.">
                  <NumberFormat
                    value={CumTicketEquv_USD}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </td>
                <td data-label="Unflown Tkts.">
                  <NumberFormat
                    value={CumunflownTickets}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </td>

                <td data-label="Uncaptured Tkts.">
                  <NumberFormat
                    value={CumuncapturedPoint}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </td>
                <td data-label="Total Tkts.">
                  <NumberFormat
                    value={CumTotalPoints}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </td>
                {/* <td data-label="Action"></td> */}
              </tr>
            </tbody>
          </table>

          <div className="bnw-table-pgnate-contain">
            <ButtonPagenate
              updatedPageNum={newPagenum}
              pageCount={pageCount}
              pageNumber={pageNumber}
              previousLabel="Prev"
              nextLabel="Next"
            />
          </div>
        </div>
      ) : activetab === 2 ? (
        <div className="bnw-claims-table-container">
          <table>
            <thead>
              <tr>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Recipient Code
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Transaction ID
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Account Number
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Bank
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Transaction Ref.
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Reasons
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Created At
                </th>

                {/* <th scope="col">Action</th> */}
              </tr>
            </thead>

            <tbody>
              {transactionhistorydata
                .slice(pagesVisited, pagesVisited + itemsPerpage)
                .map((tdata, index) => (
                  <tr
                    className="body-tab-row cursor-pointer"
                    key={tdata.id}
                    onClick={(e) => handleCountryClick(e, tdata.id)}
                  >
                    <td
                      data-label="ID"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {index + 1}
                    </td>
                    <td
                      data-label="Recip. Code"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.recipient_code}
                    </td>
                    <td
                      data-label="Trans. ID"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.transaction_id}
                    </td>
                    <td
                      data-label="Account Number"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.accountnumber}
                    </td>
                    <td
                      data-label="Account Number"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.bank}
                    </td>
                    <td
                      data-label="Amount"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      <NumberFormat
                        value={tdata.claims_equivalent}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦ "}
                      />
                    </td>
                    <td
                      data-label="Ref. Num."
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.reference_number}
                    </td>
                    <td
                      data-label="Reasons"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.transfer_reason}
                    </td>
                    <td
                      data-label="Status"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      <div
                        className={`w-full h-full py-2 text-[white] ${
                          tdata.status === "failed"
                            ? "bg-[red]"
                            : tdata.status === "pending"
                            ? "bg-[orange]"
                            : tdata.status === "successful" && "bg-[green]"
                        }`}
                      >
                        {tdata.status}
                      </div>
                    </td>
                    <td
                      data-label="Created At"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.created_at}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <div className="bnw-table-pgnate-contain">
            <ButtonPagenate
              updatedPageNum={newPagenum}
              pageCount={pageCount}
              pageNumber={pageNumber}
              previousLabel="Prev"
              nextLabel="Next"
            />
          </div>
        </div>
      ) : (
        <div className="bnw-claims-table-container">
          <div className="w-full bg-[#e50000] p-1 flex items-center justify-start gap-4">
            <input
              onChange={handleChange}
              value={searchdata}
              type="text"
              className="border text-[13px] px-2 py-1 min-w-[200px] w-[30%] outline-none rounded-md"
              placeholder="Search any text ..."
            />
          </div>

          <table>
            <thead>
              <tr>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  #SN
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  TRAN. ID
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  IP Address
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Cust. ID
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Cust. Name
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Cust. Email
                </th>
                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Transaction Ref.
                </th>

                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Status
                </th>

                <th
                  scope="col"
                  className="text-[11px] whitespace-normal break-words"
                >
                  Created At
                </th>

                {/* <th scope="col">Action</th> */}
              </tr>
            </thead>

            <tbody>
              {filteredpaystacktransactiondata
                .slice(pagesVisited, pagesVisited + itemsPerpage)
                .map((tdata, index) => (
                  <tr
                    className="body-tab-row cursor-pointer"
                    key={tdata.id}
                    onClick={(e) => handleCountryClick(e, tdata.id)}
                  >
                    <td
                      data-label="SN"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {index + 1}
                    </td>
                    <td
                      data-label="ID"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.id}
                    </td>
                    <td
                      data-label="Amount"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      <NumberFormat
                        value={addDecimal(tdata.amount)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </td>
                    <td
                      data-label="IP Address"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.ip_address}
                    </td>

                    <td
                      data-label="Cust. ID"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.customer.id}
                    </td>

                    <td
                      data-label="Fullname"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.customer.first_name +
                        " " +
                        tdata.customer.last_name}
                    </td>
                    <td
                      data-label="Email"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.customer.email}
                    </td>
                    <td
                      data-label="Reference"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.reference}
                    </td>
                    <td
                      data-label="Status"
                      className="text-[11px] whitespace-normal break-words"
                    >
                      <div
                        className={`w-full h-full py-2 text-[white] ${
                          tdata.status === "failed"
                            ? "bg-[red]"
                            : tdata.status === "abandoned"
                            ? "bg-[orange]"
                            : tdata.status === "success" && "bg-[green]"
                        }`}
                      >
                        {tdata.status}
                      </div>
                    </td>
                    <td
                      data-label="Total Tkts."
                      className="text-[11px] whitespace-normal break-words"
                    >
                      {tdata.created_at}
                    </td>
                  </tr>
                ))}

              <tr className="body-tab-rowws text-[11px] whitespace-normal break-words">
                <td data-label="">Total</td>
                <td data-label="Tran ID."></td>
                <td
                  data-label="Amount"
                  className="text-[11px] whitespace-normal break-words"
                >
                  <NumberFormat
                    value={addDecimal(cummAmount)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </td>
                <td data-label="IP Address"></td>
                <td data-label="Cust. ID"></td>
                <td data-label="Cust. Name"></td>

                <td data-label="Email"></td>
                <td data-label="Trans. Ref."></td>
                <td data-label="Status"></td>
                <td data-label="Created At"></td>
                {/* <td data-label="Action"></td> */}
              </tr>
            </tbody>
          </table>

          <div className="bnw-table-pgnate-contain">
            <ButtonPagenate
              updatedPageNum={newPagenum}
              pageCount={thirdtabpageCount}
              pageNumber={pageNumber}
              previousLabel="Prev"
              nextLabel="Next"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Wallet;

import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./allocate.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import { FaChevronDown } from "react-icons/fa";
// import Regionsjson from '../Cards/regionsjson';
import CurrencyInput from "react-currency-input-field";
import { useSelector } from "react-redux";
import { API, NodeServer_API, Paystack_url } from "../../server";
import { Store } from "../../Redux/store";
import {
  financialData,
  getAllocation,
  marketallocationData,
  paystackWalletBalance,
} from "../../Redux/Actions/dataResponse";
import { toast } from "react-toastify";
import { captureModalOverlay } from "../../Redux/Actions/buttonClick";

const Teamlist = [
  {
    name: "TeamI",
    region_code: "Nigeria",
  },
  {
    name: "TeamII",
    region_code: "CWA",
  },
];

const Budgetfinance = ({ setloading }) => {
  const { financialdata } = useSelector((state) => state.datareponse);

  const [cookies] = useCookies(["Token"]);
  const [region, setRegion] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [allocation, setAllocation] = useState(null);
  const [regionCode, setRegionCode] = useState({
    rc: "",
    rn: "",
  });

  // Make West Coast Allocation
  const MakeWestCoastPayment = async (e) => {
    e.preventDefault();
    setloading({
      statuscode: 1,
      message: "Allocating Fund",
      type: 1,
    });

    try {
      // console.log(allocation, financialdata.amount_left);
      if (allocation < financialdata.amount_left) {
        if (allocation > 100) {
          var config = {
            method: "POST",
            url: `${NodeServer_API}/finance`,
            data: {
              allocation,
              market: regionCode.rn,
            },
            headers: {
              Authorization: "Bearer " + cookies.Token,
            },
          };

          await axios(config).then((response) => {
            if (response.data.success === true) {
              Store.dispatch(
                getAllocation(
                  response.data.data === null ? [] : response.data.data
                )
              );

              FetchamountDue();
            }
          });
        } else {
          setloading({
            statuscode: 0,
            message: "",
            type: "",
          });

          toast.warning("Mimimum allocation is $100");
        }
      } else {
        setloading({
          statuscode: 0,
          message: "",
          type: "",
        });
        toast.warning("Allocation more than Available Balance!");
      }
    } catch (e) {
      if (e.response.status === 401) {
        setloading({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        setloading({
          statuscode: 2,
          message: e.message,
          type: 2,
        });
      }
    }
  };

  // Refresh to GET the current balance after deduction
  const FetchamountDue = async () => {
    try {
      var config = {
        method: "GET",
        url: `${API}/finance/home`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(financialData(response.data.data));
          toast.success("Successfully Allocated Fund");
          setloading({
            statuscode: 0,
            message: "",
            type: "",
          });
        }
      });
    } catch (e) {
      // setLoading(false);
    }
  };

  // Make Nigeria Allocation
  const MakeNigeriaPayment = async (e) => {
    e.preventDefault();
    setloading({
      statuscode: 1,
      message: "Allocating Fund",
      type: 1,
    });

    try {
      // console.log(allocation, financialdata.amount_left);
      if (allocation > 10000) {
        var config = {
          method: "POST",
          url: `${NodeServer_API}/finance`,
          data: {
            allocation,
            market: regionCode.rn,
          },
          headers: {
            Authorization: "Bearer " + cookies.Token,
          },
        };

        await axios(config).then((response) => {
          if (response.data.success === true) {
            setAllocation(null)
            Store.dispatch(
              marketallocationData(
                response.data.data === null ? [] : response.data.data
              )
            );

            FetchCurrentNigeriaBalance();

            setloading({
              statuscode: 0,
              message: "",
              type: "",
            });

            toast.success("Allocation Successful");

          }
        });
      } else {
        setloading({
          statuscode: 0,
          message: "",
          type: "",
        });

        toast.warning("Mimimum allocation is ₦10000");
      }
    } catch (e) {
      if (e.response.status === 401) {
        setloading({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        setloading({
          statuscode: 2,
          message: e.message,
          type: 2,
        });
      }
    }
  };

  // Refresh to GET the current balance after deduction
  const FetchCurrentNigeriaBalance = async () => {
    try {
      var config = {
        method: "GET",
        url: `${Paystack_url}/balance`,
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_PAYSTACK_SECRET_KEY,
        },
      };

      await axios(config).then((response) => {
        if (response.data.status === true) {
          Store.dispatch(paystackWalletBalance(response.data.data[0].balance));
        }
      });
    } catch (e) {
      // console.log("error: ", e.response);
    }
  };

  const handleClose = () => {
    Store.dispatch(captureModalOverlay("close"));
  };

  const handleRegionFocus = () => {
    setRegion(!region);
  };

  const handleDrpdwnClick = (e, incoming) => {
    if (incoming.rc === "Nigeria") {
      setToggle(true);
    } else {
      setToggle(false);
    }
    setRegionCode(incoming);
    setRegion(false);
  };

  return (
    <div className="overlay-container z-10 ">
      <div className="overlay-addemployee-container">
        <div className="top-container-apprais-notif">
          <span>TEAM'S BUDGET</span>
          <AiOutlineCloseCircle id="closeIcon-notifc" onClick={handleClose} />
        </div>

        <form
          onSubmit={!toggle ? MakeWestCoastPayment : MakeNigeriaPayment}
          className="top-addemployee-container-body"
        >
          <div className="form-row-addrow">
            <div className="bnw-dirname-container">
              {/* <Select onChange={setTofield} options={ Regionsjson } className='bnw-regionfield' placeholder='Regions'/> */}
              <input
                readOnly
                value={regionCode.rn}
                onFocus={handleRegionFocus}
                placeholder="Team"
                id="bnw-firname-box"
                onClick={handleRegionFocus}
              />
              <FaChevronDown
                onClick={handleRegionFocus}
                className="bnw-chevrondown"
              />
              {region && (
                <div
                  className="bnw-region-drpdwn"
                  onMouseLeave={handleRegionFocus}
                >
                  {Teamlist.map((item, key) => (
                    <span
                      onClick={(e) =>
                        handleDrpdwnClick(e, {
                          rc: item.region_code,
                          rn: item.name,
                        })
                      }
                      key={key}
                    >
                      {item.name} - {item.region_code}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="form-row-addrow">
            <div className="bnw-dirname-container">
              <CurrencyInput
                id="input-example"
                name="input-name"
                placeholder={toggle ? "₦ 0.00" : "$ 0.00"}
                decimalsLimit={2}
                prefix={toggle ? "₦" : "$"}
                onValueChange={(value, name) => setAllocation(parseInt(value))}
              />
            </div>
          </div>

          <div className="top-addemployee-submit-row">
            <input id="broadcast-buttn" type="submit" value="SUBMIT" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Budgetfinance;

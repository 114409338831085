
const TPRJson = [
    {
        url: "/flag/ng.svg",
        region: "Nigeria"
    },
    {
        url: "/flag/gh.svg",
        region: "Ghana"
    },
    {
        url: "/flag/ml.svg",
        region: "Mali"
    },
    {
        url: "/flag/ci.svg",
        region: "Côte d'Ivoires"
    },
    {
        url: "/flag/bj.svg",
        region: "Benin"
    },
    {
        url: "/flag/mr.svg",
        region: "Mauritania"
    },
    {
        url: "/flag/sn.svg",
        region: "Senegal"
    },
    {
        url: "/flag/ci.svg",
        region: "Côte d'Ivoire"
    },
    {
        url: "/flag/ne.svg",
        region: "Niger"
    },
    {
        url: "/flag/cm.svg",
        region: "Cameroon"
    },
    {
        url: "/flag/lr.svg",
        region: "Liberia"
    },
    {
        url: "/flag/ga.svg",
        region: "Gabon"
    },
    {
        url: "/flag/bf.svg",
        region: "Burkina Faso"
    },
    {
        url: "/flag/gn.svg",
        region: "Guinea"
    },
    {
        url: "/flag/gm.svg",
        region: "Gambia"
    },
    {
        url: "/flag/ng.svg",
        region: "Lagos Main-Land"
    },
    {
        url: "/flag/ng.svg",
        region: "Central West Africa ADMIN"
    },
    {
        url: "/flag/ng.svg",
        region: "Central West Africa Finance"
    }

]

export default TPRJson
import { createAction, createReducer } from "@reduxjs/toolkit";

const Captured_pocket_Data = createAction("Captured_pocket_Data");
const Top_Performing_Agencies = createAction("Top_Performing_Agencies");

const initialState = {
  pocketdata: {},
  topagencies: [],
};

export const pocketReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Captured_pocket_Data, (state, action) => {
      state.pocketdata = action.payload;
    })

    .addCase(Top_Performing_Agencies, (state, action) => {
      state.topagencies = action.payload;
    });
});

import React, { useState } from "react";
import "./Cards.css";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const Travels = ({ agencyname, agentname, percent, imgurl }) => {
  const [visible, setVisible] = useState(false);

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <>
    <Tooltip TransitionComponent={Zoom} title={agentname} followCursor sx={{fontSize: '9px'}}>
      <div
        className="w-full py-[5px] flex justify-between items-center cursor-pointer hover:bg-[#ecc2] px-[8px] relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="w-[80%] min-w-[80px] flex items-center">
          <div
            id="bnw-travels-img"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + imgurl})`,
            }}
          />
          {/* <div id='bnw-travels-img' style={{backgroundImage: `url(${imgurl})`}}/> */}
          <span className="font-[600] text-[11px]">{agencyname}</span>
        </div>
        <span className="font-[700] text-[11px]">{percent}%</span>
      </div>
    </Tooltip>
    </>
  );
};

export default Travels;

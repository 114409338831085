import React, {useState, useEffect} from 'react'
import './promorate.css'
import SLoader from '../Component/Loading/SigninLoader'
import axios from 'axios'
import { useCookies } from 'react-cookie';
import ButtonPagenate from '../Component/ButtonPagenate/ButtonPagenate';
import Regionsjson from '../Component/Cards/regionsjson';
import { API } from '../server';


const Promo = ({setadd, setExpire}) => {
  const [cookies] = useCookies(['Token']);
  const [loading, setLoading] = useState(false)
  const [promodata, setPromodata] = useState([])
  const [pageNumber, setPageNumber] = useState(0);

  const itemsPerpage = 20; //Number of items to be displayed per page 
  const pageCount = Math.ceil(promodata.length / itemsPerpage);
  // // console.log(incomingdata.length)
  // // console.log(pageCount)
  const pagesVisited = pageNumber * itemsPerpage;

  useEffect(()=>{ 
    GetPromo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const GetPromo = async () =>{
    try{
    // // console.log(`Tokenme: ${cookies.Token}`)
    setLoading(true)
    var config = {
      method: 'GET',
      url: `${API}/agents`,
      headers: { 
        Authorization: "Bearer " + cookies.Token
      }
    };

    await axios(config).then((response) => {
    if(response.data.success === true){
      // // console.log(JSON.stringify(response.data));
      setPromodata(response.data.data)
      setLoading(false)
    }});}
    catch(e){
      if(e.message.includes('401')){
        // // console.log(`Error `)
        // setConfirmresponse(3)
        setLoading(false)
        setExpire()
      } else{
        setLoading(false)
        // setConfirmresponse(3)
      }
    }
  }

  const newPagenum = (num) =>{
    setPageNumber(num)
  }

  const handleAddButtn = () =>{
    setadd()
  }

  return (
    // <div className='bnw-promopg-container'>Promo</div>
    <>
      {loading ? <SLoader type="bars" text = "Fetching Data"/> : 
      <div className='bnw-promopg-container'>
        <table className='bnw-claims-table'>
          <thead id='bnw-claims-table-head'>
            <tr>
              <th scope="col">User ID</th>
              <th scope="col">Status</th>
              <th scope="col">Promo Name</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Region</th>
            </tr>
          </thead>
          <tbody>
            {promodata.reverse().slice(pagesVisited, pagesVisited + itemsPerpage).map((item, key) => (
              <tr key={key}>
                <td data-label="USER ID">{item.ID}</td>
                <td data-label="STATUS">{item.status}</td>
                <td data-label="PROMO_NAME">{item.name}</td>
                <td data-label="START-DATE">{item.start_date}</td>
                <td data-label="END-DATE">{item.end_date}</td>
                <td data-label="REGION">{(Regionsjson.find(m => m.value === item.region_code)).label}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='bnw-table-pgnate-contain'>
          <button id='addbitn' onClick={handleAddButtn} className="text-[13px]">Add</button>
          <ButtonPagenate 
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"/>
        </div>
      </div>}
      {/* {expiration && <Error message={"Session Expired Re-Login"} isTimeout={true} icon="3"/>} */}
    </>
  )
}

export default Promo
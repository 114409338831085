import React, { useState, useEffect } from "react";
import Linegraph from "../Linegraph/linegraph";
import "./Cards.css";
import { useSelector } from "react-redux";
import { groupAllocationsByMarket } from "../charts/Salesfunnelchart";

let result = {};

const Salesfunnel = () => {
  const { selectedyear } = useSelector((state) => state.buttonclick);

  const [mycheckbox, setMycheckbox] = useState(false);

  const { selectedmonth } = useSelector((state) => state.buttonclick);
  // console.log(selectedmonth);

  const { financialdata, marketallocationdata } = useSelector(
    (state) => state.datareponse
  );

  let financeAllocations = financialdata?.finance_allocations;
  const [monthlySales, setMonthlySales] = useState(result);

  useEffect(() => {
    result = groupAllocationsByMarket(marketallocationdata);

    // console.log(result);
    setMonthlySales(result);
  }, [financeAllocations]);

  // // console.log(result);

  return (
    <div className="bnw-sfunnelcard-container">
      <div className="bnw-salesfun-text-contain">
        <span className="text-[13px] font-[800] text-[#323C4D]">
          Funds Disbursed Overtime
        </span>

        <div className="w-fit gap-2 flex items-center text-[12px] font-[500]">
          <span>Year: </span>
          <span>{selectedyear}</span>
        </div>

        <div className="flex items-center gap-4">
          <div className="flex item-center gap-1">
            <input
              type="checkbox"
              onChange={() => setMycheckbox(!mycheckbox)}
              checked={mycheckbox ? true : false}
              className="cursor-pointer"
            />
            <label
              htmlFor="checkbox"
              className="text-[12px] font-[500] text-[green]"
            >
              Team I
            </label>
          </div>

          <div className="flex item-center gap-1">
            <input
              type="checkbox"
              onChange={() => setMycheckbox(!mycheckbox)}
              checked={!mycheckbox ? true : false}
              className="cursor-pointer"
            />
            <label
              htmlFor="checkbox"
              className="text-[12px] font-[500] text-[red]"
            >
              Team II
            </label>
          </div>
        </div>
      </div>
      <div className="bnw-salesfun-linegraph">
        <Linegraph
          mycheck={mycheckbox}
          jan={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.jan
              : monthlySales[selectedyear]?.TeamI.jan
          }
          feb={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.feb
              : monthlySales[selectedyear]?.TeamI.feb
          }
          mar={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.mar
              : monthlySales[selectedyear]?.TeamI.mar
          }
          apr={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.apr
              : monthlySales[selectedyear]?.TeamI.apr
          }
          may={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.may
              : monthlySales[selectedyear]?.TeamI.may
          }
          jun={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.jun
              : monthlySales[selectedyear]?.TeamI.jun
          }
          jul={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.jul
              : monthlySales[selectedyear]?.TeamI.jul
          }
          aug={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.aug
              : monthlySales[selectedyear]?.TeamI.aug
          }
          sep={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.sep
              : monthlySales[selectedyear]?.TeamI.sep
          }
          oct={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.oct
              : monthlySales[selectedyear]?.TeamI.oct
          }
          nov={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.nov
              : monthlySales[selectedyear]?.TeamI.nov
          }
          dec={
            !mycheckbox
              ? monthlySales[selectedyear]?.TeamII.dec
              : monthlySales[selectedyear]?.TeamI.dec
          }
        />
      </div>
    </div>
  );
};

export default Salesfunnel;

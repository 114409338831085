import React, { useState, useEffect } from "react";
import { captureSelectedYear } from "../../Redux/Actions/buttonClick";
import { Store } from "../../Redux/store";

const YearSelector = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: 6 },
      (_, index) => currentYear - 5 + index
    );

    setYearOptions(years);
    Store.dispatch(captureSelectedYear(currentYear.toString()));
    setSelectedYear(currentYear.toString());
  }, []);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    Store.dispatch(captureSelectedYear(e.target.value));
  };

  return (
    <div className="flex items-center gap-2">
      <label className="text-[11px]" htmlFor="year">
        Year:{" "}
      </label>
      <select
        className="py-1 px-2 outline-none border text-[12px]"
        id="year"
        value={selectedYear}
        onChange={handleYearChange}
      >
        {yearOptions.map((year) => (
          <option
            className="text-[12px] p-2"
            key={year}
            value={year.toString()}
          >
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default YearSelector;

const agencybreakdownbyperformance = (agencyData) => {
  // Calculate total scores for each agency
  const agenciesWithScores = agencyData.map((agency) => ({
    ...agency,
    totalScore:
      parseInt(agency.active_points) + parseInt(agency.unflown_tickets),
  }));

  // Sort agencies based on total scores in descending order
  const sortedAgencies = agenciesWithScores.sort(
    (a, b) => b.totalScore - a.totalScore
  );

  // Calculate the total scores of the top 5 agencies
  const totalTop5Scores = sortedAgencies.reduce(
    (total, agency) => total + agency.totalScore,
    0
  );

  // Calculate the percentage of scores for each agency and append it to the object
  const agenciesWithPercentage = sortedAgencies.map((agency) => ({
    ...agency,
    percentage: ((agency.totalScore / totalTop5Scores) * 100).toFixed(1),
  }));

  return agenciesWithPercentage;
};

export default agencybreakdownbyperformance;

import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Dashboard from './Pages/dashboard';
import Loginpg from './Pages/Login';
import PrivateRoute from './Protected';
import Footer from './Component/FooterSup';
import Navbar from './Component/NavBar/Navbar';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const App = () =>{
  
  return (
    <Router>
      <Navbar />
      <div className="appcontainer">
          <Routes>
            <Route exact path="/" element={<Loginpg />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard/>}/>
            </Route>
          </Routes>
      </div>
      <Footer />

      <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
    </Router>
  );
}

export default App;

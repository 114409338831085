import React, { useState } from "react";
import "./Box.css";
import { AiTwotoneCloud } from "react-icons/ai";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
// import ReactCursorPosition from 'react-cursor-position';

const Box = ({ tooltiptext, boxname, boxvalue, color }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [visible, setVisible] = useState(false);

  const handleMouseMove = (event) => {
    // tooltip(localCoords)
    setPosition({
      x: event.clientX - event.target.offsetLeft,
      y: event.clientY - event.target.offsetTop,
    });
  };

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <>
      <Tooltip TransitionComponent={Zoom} title={tooltiptext} followCursor>
        <div
          className="claims-analysis-container text-center"
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ backgroundColor: color }}
        >
          <span className="text-[#fff] text-[16px] font-[600]">{boxname}</span>
          <span className="text-[#fff] text-[20px] font-[800]">{boxvalue}</span>

          <div className="overlay-top" />

          {visible && (
            <Tooltip
              content={tooltiptext}
              topcoord={position.y}
              leftcoord={position.x}
            />
          )}
          <AiTwotoneCloud id="cloud-id" />
        </div>
      </Tooltip>
    </>
  );
};

export default Box;

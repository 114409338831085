export const formattedFinanceChart = (inputData) => {
  // Initialize the result object
  const result = {};

  // Populate the result object with actual ticket values
  inputData.forEach((item) => {
    const teamKey = `Team${item.team}`;
    const [month, year] = item.monthYear.split(" ");

    if (!result[year]) {
      result[year] = {};
    }

    if (!result[year][teamKey]) {
      result[year][teamKey] = Array(12).fill(0);
    }

    const monthIndex = getMonthIndex(month);
    result[year][teamKey][monthIndex] = item.tickets;
  });

  // Helper function to get the month index
  function getMonthIndex(month) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.indexOf(month);
  }

  return result;
};

import React, { useState, useEffect } from "react";
import { Store } from "../../Redux/store";
import { captureSelectedMonth } from "../../Redux/Actions/buttonClick";

const MonthSelector = () => {
  const [selectedMonth, setSelectedMonth] = useState("");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    // Set the default month to the current month
    const currentMonth = new Date().getMonth();
    setSelectedMonth(months[currentMonth]);
    // Store.dispatch(captureSelectedMonth(months[currentMonth]));
  }, []); // Run this effect only once on mount

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    Store.dispatch(captureSelectedMonth(e.target.value));
  };

  return (
    <div className="flex items-center gap-2 text-[13px]">
      <label className="text-[11px]" htmlFor="year">
        Month:{" "}
      </label>

      <select
        id="monthSelector"
        value={selectedMonth}
        onChange={handleMonthChange}
        className="py-1 px-2 border outline-none text-[12px]"
      >
        {months.map((month, index) => (
          <option key={index} value={month}>
            {month}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MonthSelector;

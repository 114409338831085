export const getAllAgencies = (agenciesList) => ({
  type: "AgencyList",
  payload: agenciesList,
});

export const getAllDirectors = (directorList) => ({
  type: "DirectorList",
  payload: directorList,
});

export const getActiveClaims = (activeclaims) => ({
  type: "ActiveClaims",
  payload: activeclaims,
});

export const getApprovedClaims = (approvedclaims) => ({
  type: "ApprovedClaims",
  payload: approvedclaims,
});

export const getCanceledClaims = (canceledclaims) => ({
  type: "CanceledClaims",
  payload: canceledclaims,
});

export const getAllRegions = (regionsList) => ({
  type: "RegionsList",
  payload: regionsList,
});

export const getAllExchangerate = (exchangerateList) => ({
  type: "ExchangeRateList",
  payload: exchangerateList,
});

export const getRole = (role) => ({
  type: "Role",
  payload: role,
});

export const getName = (fullname) => ({
  type: "Fullname",
  payload: fullname,
});

export const getAllocation = (allocations) => ({
  type: "AllocationsList",
  payload: allocations,
});

export const financialData = (finacialdata) => ({
  type: "FinancialAllocation",
  payload: finacialdata,
});

export const pointBreakdown = (prointsdata) => ({
  type: "PointBreakdown",
  payload: prointsdata,
});

export const homepageData = (homepagedata) => ({
  type: "HomepageData",
  payload: homepagedata,
});

export const paystackWalletBalance = (paystackdata) => ({
  type: "PaystackWalletBalance",
  payload: paystackdata,
});

export const paystackTotalDisbursed = (totaldisbursed) => ({
  type: "PaystackTotalDisbursed",
  payload: totaldisbursed,
});

export const paystackTransactionHistory = (transactionhistory) => ({
  type: "PaystackTransactionHistory",
  payload: transactionhistory,
});

export const pointsData = (pointsdata) => ({
  type: "PointsData",
  payload: pointsdata,
});

export const marketallocationData = (marketdata) => ({
  type: "MarketallocationData",
  payload: marketdata,
});

export const transactionhistoryData = (transactdata) => ({
  type: "TransactionhistoryData",
  payload: transactdata,
});

export const claimsData = (claimsdata) => ({
  type: "ClaimsData",
  payload: claimsdata,
});

export const flownticketSumsData = (flownticketdata) => ({
  type: "FlownticketSumsData",
  payload: flownticketdata,
});

export const getMonthNumber = (monthName) => {
  // Create an array of month names
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the index of the month name
  const monthIndex = months.findIndex((month) => month === monthName);

  // Increment by 1 to match JavaScript's Date.getMonth() method
  const monthNumber = monthIndex + 1;

  // Convert to a two-digit string
  const formattedMonthNumber = monthNumber.toString().padStart(2, "0");

  return formattedMonthNumber;
};

import React, { useState, useEffect } from "react";
import "./page.css";
import ButtonPagenate from "../Component/ButtonPagenate/ButtonPagenate";
// import AgencyTableJson from './agencytable';
// import Toggle from '../Component/Togglebutton/Togglebutton'
import { useCookies } from "react-cookie";
import axios from "axios";
import SLoader from "../Component/Loading/SigninLoader";
import Regionsjson from "../Component/Cards/regionsjson";
import Toggles from "../Component/Togglebutton/Togglebuttons";
import { useSelector } from "react-redux";
import { API } from "../server";
import { FiUpload } from "react-icons/fi";
import { CSVLink } from "react-csv";
import { FcStatistics } from "react-icons/fc";
// import Error from '../Component/Error';

const Agencies = ({ setreat, setloading, nameSearch }) => {
  const criteria = useSelector((state) => state.searchdata.searchtext);

  const [cookies] = useCookies(["Token"]);
  const [incomingdata, setIncomingdata] = useState([]);
  const [treatConfirm, setTreatConfirm] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [confirmresponse, setConfirmresponse] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exportpro, setExportpro] = useState(false);
  const [statusupdate, setStatusupdate] = useState(0);
  const itemsPerpage = 20; //Number of items to be displayed per page
  const pageCount = Math.ceil(incomingdata.length / itemsPerpage);
  // // console.log(incomingdata.length)
  // // console.log(pageCount)
  const pagesVisited = pageNumber * itemsPerpage;

  useEffect(() => {
    Makerolecall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Makerolecall = async () => {
    setloading({
      statuscode: 1,
      message: "Fetching Agencies",
      type: 1,
    });

    try {
      setLoading(true);
      var config = {
        method: "GET",
        url: `${API}/agents`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          setloading({
            statuscode: 0,
            message: "",
            type: 0,
          });
          setIncomingdata(
            response.data.data === null ? [] : response.data.data
          );
        }
      });
    } catch (e) {
      if (e.response.status === 401) {
        setloading({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        setloading({
          statuscode: 2,
          message: e.message,
          type: 2,
        });
      }
    }
  };

  const Treated = (incoming) => {
    // console.log("incoming :", incoming);
    // setUpdaterow(incoming.id);
    setreat(incoming);
  };

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const loginStatusObj = {
    1: "Active",
    2: "In-Active",
  };

  var newdataset = incomingdata.filter((filteredItem) => {
    if (criteria === "" && nameSearch === "") {
      return filteredItem;
    } else if (criteria !== "" && nameSearch === "") {
      return filteredItem;
    } else if (criteria === "" && nameSearch !== "") {
      return filteredItem;
    } else if (criteria !== "" && nameSearch !== "") {
      if (criteria === "User Id") {
        if (
          filteredItem.user_id.toString().includes(nameSearch.toLowerCase())
        ) {
          return filteredItem;
        }
      } else if (criteria === "Name") {
        if (
          filteredItem.firstname
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        ) {
          return filteredItem;
        }
      } else if (criteria === "Agency Name") {
        if (
          filteredItem.agencyName
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        ) {
          return filteredItem;
        }
      } else {
        return filteredItem;
      }
    }
  });

  const csvHeaders = [
    { label: "Id", key: "ID" },
    { label: "Firstname", key: "first_name" },
    { label: "Lastname", key: "last_name" },
    { label: "Agency Name", key: "agency_name" },
    { label: "Phone Number", key: "phone" },
    { label: "Email Address", key: "email" },
    { label: "Region", key: "region_code" },
  ];

  const handleHover = () => {
    setExportpro(!exportpro);
  };

  const handleExportCSV = () => {};

  return (
    <div className="bnw-agency-container flex flex-col gap-1">
      <div className="w-full justify-between flex bg-[#e50000] p-1">
        <div className="relative flex justify-center items-center">
          <span className="flex items-center px-2 py-1 text-center cursor-pointer text-[#fff] text-[12px] font-[600]">
            AGENCIES
          </span>

          <FcStatistics size={25} color="#fff" />
        </div>

        <CSVLink data={incomingdata} headers={csvHeaders}>
          <div
            className={`relative border px-2 py-1 cursor-pointer rounded-md flex justify-center items-center ${
              exportpro ? "bg-[white]" : "bg-[#e50000]"
            }`}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            onClick={handleExportCSV}
          >
            <FiUpload size={18} color={exportpro ? "#e50000" : "#fff"} />
            <span
              className={`px-2 py-1 cursor-pointer ${
                exportpro ? "text-[#e50000]" : "text-[#fff]"
              } text-[12px] font-[600]`}
            >
              Export
            </span>
          </div>
        </CSVLink>
      </div>

      <div className="w-full flex flex-col gap-1">
        <table className="bnw-claims-table">
          <thead id="bnw-claims-table-head">
            <tr>
              <th scope="col" className="text-[13px]">
                Can-Login
              </th>
              <th scope="col" className="text-[13px]">
                Status
              </th>
              <th scope="col" className="text-[13px]">
                User ID
              </th>
              <th scope="col" className="text-[13px]">
                Name
              </th>
              <th scope="col" className="text-[13px]">
                Agency Name
              </th>
              <th scope="col" className="text-[13px]">
                Phone Number
              </th>
              <th scope="col" className="text-[13px]">
                Email Addr
              </th>
              <th scope="col" className="text-[13px]">
                Region
              </th>
            </tr>
          </thead>
          <tbody>
            {newdataset
              .reverse()
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((item, key) => (
                <tr key={key}>
                  <td data-label="CAN-LOGIN" className="text-[11px]">
                    <Toggles
                      currentState={item.canLogin}
                      setState={Treated}
                      regionState={
                        Regionsjson.find((m) => m.value === item.region_code)
                          .label
                      }
                      index={item.ID}
                      emailState={item.email}
                    />
                  </td>
                  <td
                    data-label="STATUS"
                    id="status-col"
                    className="text-[11px]"
                  >
                    <div
                      id={item.active ? "bnw-status-act" : "bnw-status-deact"}
                    >
                      {item.active ? loginStatusObj[1] : loginStatusObj[2]}
                    </div>
                  </td>
                  <td data-label="USER ID" className="text-[11px]">
                    {item.ID}
                  </td>
                  <td data-label="NAME" className="text-[11px]">
                    {item.first_name + " " + item.last_name}
                  </td>
                  <td data-label="AGENCY NAME" className="text-[11px]">
                    {item.agency_name}
                  </td>
                  <td data-label="PHONE" className="text-[11px]">
                    {item.phone}
                  </td>
                  <td data-label="EMAIL" className="text-[11px]">
                    {item.email}
                  </td>
                  <td data-label="REGION" className="text-[11px]">
                    {
                      Regionsjson.find((m) => m.value === item.region_code)
                        .label
                    }
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="bnw-table-pgnate-contain">
          {/* <button id='addbitn' onClick={handleAddButtn}>Add</button> */}
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default Agencies;

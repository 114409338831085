const mergeAllocationsWithLabels = (allocations, labels) => {
  const mergedAllocations = {};

  allocations.forEach((allocation) => {
    const regionCode = allocation.region_code;
    const labelObject = labels.find((label) => label.value === regionCode);
    const label = labelObject ? labelObject.label : "";

    if (!mergedAllocations[regionCode]) {
      mergedAllocations[regionCode] = {
        ...allocation,
        label: label,
      };
    } else {
      const existingAllocation = mergedAllocations[regionCode];

      // Compare CreatedAt dates and retain the oldest
      if (
        new Date(allocation.CreatedAt) < new Date(existingAllocation.CreatedAt)
      ) {
        existingAllocation.CreatedAt = allocation.CreatedAt;
      }

      // Compare UpdatedAt dates and retain the most recent
      if (
        new Date(allocation.UpdatedAt) > new Date(existingAllocation.UpdatedAt)
      ) {
        existingAllocation.UpdatedAt = allocation.UpdatedAt;
      }

      // Sum the allocation values
      existingAllocation.allocation += allocation.allocation;
    }
  });

  return Object.values(mergedAllocations);
};

export default mergeAllocationsWithLabels;

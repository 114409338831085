export const sumAllocationsByYearAndMarket = (data) => {
  const result = {};

  data.forEach((item) => {
    const year = new Date(item.created_at).getFullYear().toString();
    if (!result[year]) {
      result[year] = {};
    }

    if (!result[year][item.market]) {
      result[year][item.market] = 0;
    }

    result[year][item.market] += item.allocation;
  });

  return result;
};

import { createAction, createReducer } from "@reduxjs/toolkit";

const CAPTURE_Sidebar_Tab = createAction("CAPTURE_Sidebar_Tab");
const Selected_Month = createAction("Selected_Month");
const Top_performing_agency = createAction("Top_performing_agency");
const Add_New_Modal = createAction("Add_New_Modal");
const Selected_Year = createAction("Selected_Year");


// reducers.js
const initialState = {
  buttonClicks: "Main",
  selectedmonth: "All",
  topperformclick: 0,
  newmodal: 'close',
  selectedyear: ""
};

export const buttonClickReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CAPTURE_Sidebar_Tab, (state, action) => {
      state.buttonClicks = action.payload;
    })

    .addCase(Selected_Month, (state, action) => {
      state.selectedmonth = action.payload;
    })

    .addCase(Selected_Year, (state, action) => {
      state.selectedyear = action.payload
    })

    .addCase(Top_performing_agency, (state, action) => {
      state.topperformclick = action.payload;
    })

    .addCase(Add_New_Modal, (state, action) => {
      state.newmodal = action.payload;
    })
});

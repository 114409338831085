export const groupAllocationsByYear = (allocations) => {
    const groupedAllocations = {};

    allocations.forEach((allocation) => {
      const createdAt = new Date(allocation.CreatedAt);
      const year = createdAt.getFullYear().toString();
      const month = createdAt.toLocaleString('en-US', { month: 'long' });
  
      if (!groupedAllocations[year]) {
        groupedAllocations[year] = {};
      }
      if (!groupedAllocations[year][month]) {
        groupedAllocations[year][month] = [];
      }
      groupedAllocations[year][month].push(allocation);
    });
  
    return groupedAllocations;
  };
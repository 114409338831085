export const groupAllocationsByMarket = (financeAllocations) => {
  const yearSums = {};

  financeAllocations?.forEach((allocation) => {
    const updatedAt = new Date(allocation.created_at);
    const year = updatedAt.getFullYear();
    const month = updatedAt
      .toLocaleString("en-US", { month: "short" })
      .toLowerCase();

    if (!yearSums[year]) {
      yearSums[year] = {};
    }
    if (!yearSums[year][allocation.market]) {
      yearSums[year][allocation.market] = {
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
      };
    }

    yearSums[year][allocation.market][month] += allocation.allocation;
  });

  return yearSums;
};

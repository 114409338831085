import React, { useState, useEffect } from "react";
import Boardcard from "../Boardcard/Boardcard";
import "./Cards.css";

const Claimcards = ({
  backcolor,
  strokecolor,
  text,
  value,
  maximum,
  gradient,
  piebackcolor,
}) => {
  const [PercentValue, setPercentValue] = useState();
  const [claimCount, setClaimCount] = useState();

  useEffect(() => {
    SetActiveClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SetActiveClaims = () => {
    setClaimCount(value); // Value of Claim Count coming from the DB
    setPercentValue((value / maximum) * 100);
  };
  return (
    <div
      className="bnw-claimcard-container h-fit text-[#323C4D]"
      style={{ backgroundColor: backcolor }}
    >
      <span className="text-[13px] font-[800]">{text}</span>
      <Boardcard val={value} maxV={maximum} minV={0} backgrd={strokecolor} />
    </div>
  );
};

export default Claimcards;

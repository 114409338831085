const Numberformat = (value, symbol) =>{
    let count = 1;
    let result 
    let valueLeng = value?.length
    if(valueLeng > 3){
        for(let i = (valueLeng - 1); i > -1; i--){
            // // console.log(i)
            if(count === 3 && i !== 0){
                let newarrValue = Array?.from(value)
                newarrValue.splice(i, 0, ',')
                count = 0
                value = newarrValue
            }
            ++count;
        }
        result = symbol + value?.join("") + '.00'
    }else{
        result = symbol + value + '.00'
    }

    return result
}

export default Numberformat
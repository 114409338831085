const currencyUnit = [
    {
        id: 1, 
        region_code: 'CI', 
        name: "Cote d'voir",
        currency: 'XOF',
        symbol: 'XOF'
    },
    {
        id: 2, 
        region_code: 'NG', 
        name: "Nigeria",
        currency: 'NGN',
        symbol: '₦'
    },
    {
        id: 3, 
        region_code: 'GH', 
        name: "Ghana",
        currency: 'GHS',
        symbol: 'GH¢'
    },
    {
        id: 4, 
        region_code: 'ML', 
        name: "Mali",
        currency: 'XOF',
        symbol: 'XOF'
    },
    {
        id: 5, 
        region_code: 'BJ', 
        name: "Benin",
        currency: 'XOF',
        symbol: 'XOF'
    },
    {
        id: 6, 
        region_code: 'GB', 
        name: "Gabon",
        currency: 'XOF',
        symbol: 'XOF'
    },
    {
        id: 7, 
        region_code: 'SN', 
        name: "Senegal",
        currency: 'XOF',
        symbol: 'XOF'
    },
    {
        id: 8, 
        region_code: 'MR', 
        name: "Marutania",
        currency: 'MUR',
        symbol: 'MUR'
    },
    {
        id: 9, 
        region_code: 'LR', 
        name: "Liberia",
        currency: 'LDR',
        symbol: 'LDR'
    },
    {
        id: 10, 
        region_code: 'TG', 
        name: "Togo",
        currency: 'CFA',
        symbol: 'CFA'
    },
    {
        id: 11, 
        region_code: 'CM', 
        name: "Cameroon",
        currency: 'CFA',
        symbol: 'CFA'
    },
    {
        id: 12, 
        region_code: 'BF', 
        name: "Burkina Faso",
        currency: 'CFA',
        symbol: 'CFA'
    }
    
]

export default currencyUnit
import React from "react";
import CanvasJSReact from "./canvasjs.react";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Linegraph = ({
  mycheck,
  jan,
  feb,
  mar,
  apr,
  may,
  jun,
  jul,
  aug,
  sep,
  oct,
  nov,
  dec,
}) => {
  // // console.log(jan)
  const currentYear = new Date().getFullYear();
  const options = {
    animationEnabled: true,
    title: {
      text: "",
    },
    axisY: {
      title: !mycheck ? "Budgets (in USD)" : "Budgets (in NGN)",
      prefix: !mycheck ? "$" : "₦"
    },
    data: [
      {
        type: "area",
        xValueFormatString: "MMM",
        yValueFormatString: "$#,###",
        showInLegend: false,
        dataPoints: [
          { x: new Date(currentYear, 0), y: jan },
          { x: new Date(currentYear, 1), y: feb },
          { x: new Date(currentYear, 2), y: mar },
          { x: new Date(currentYear, 3), y: apr },
          { x: new Date(currentYear, 4), y: may },
          { x: new Date(currentYear, 5), y: jun },
          { x: new Date(currentYear, 6), y: jul },
          { x: new Date(currentYear, 7), y: aug },
          { x: new Date(currentYear, 8), y: sep },
          { x: new Date(currentYear, 9), y: oct },
          { x: new Date(currentYear, 10), y: nov },
          { x: new Date(currentYear, 11), y: dec },
        ],
      },
    ],
  };
  return (
    <div style={{ height: "300px", width: "100%" }}>
      <CanvasJSChart
        options={options}
        /* onRef={ref => this.chart = ref} */
      />
      {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
    </div>
  );
};
export default Linegraph;

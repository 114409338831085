const SortByjson = [
    {
        id: 1, 
        value: "User Id"
    },
    {
        id: 2, 
        value: "Name"
    },
    {
        id: 3, 
        value: "Agency Name"
    },
    {
        id: 4, 
        value: "Region"
    }
]

export default SortByjson
import React,{useState} from 'react'
import DatePicker from "react-datepicker"
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker2 = ({selected, start, currentdate, linestate, placeholder}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleDateSelect = (date) =>{
      selected(date)
    }

    const handleendDateSelect = (date) =>{
      selected(date)
    }

    return (
      <DatePicker
        selected={start ? startDate : endDate}
        onChange={start ? (date) => setStartDate(date) : (date) => setEndDate(date)}
        onSelect={start ? handleDateSelect : handleendDateSelect}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm:ss a"
        showTimeInput
        inline={linestate}
        calendarClassName="cal-cal-endar"
        autoFocus = {false} 
        id="datepicker"
        minDate={start ? moment().toDate() : Date.parse(currentdate) } 
        placeholderText={placeholder}
        className='my-dateclass'
        dropdownMode='scroll'
        fixedHeight={true}
        showPopperArrow={false}
      />
    );
  };

export default DatePicker2
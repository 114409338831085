const agencyBreakdwonScript = (inputdata) => {
  const result = [];

  Object.keys(inputdata).forEach((country) => {
    const countryData = inputdata[country];

    if (countryData) {
      //get Agency name
      let newCountrydata = countryData.map((data, index) => {
        return data;
      });

      result.push(...newCountrydata);
    }
  });

  return result;
};

export default agencyBreakdwonScript;

import React, {useState} from 'react'
import './style.css'
import { RiCloseCircleFill, RiSignalWifiErrorFill } from "react-icons/ri";
import { FaRegThumbsUp } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { GiSandsOfTime} from "react-icons/gi";
// import ErrorIcon from '../assets/error.svg';
import { Link } from 'react-router-dom';

const Error = ({closeOverlay, message, icon, isTimeout}) => {
    // const [close, setClose] = useState(false)
    const Iconobj = {
      1 : <FaRegThumbsUp id='confIcon'/>,
      2 : <TiCancel id='errorIcon'/>,
      3 : <GiSandsOfTime id='errorIcon'/>,
      4 : <div id='serverError'/>,
      5 : <RiSignalWifiErrorFill id='serverError'/>
    }
    const handleClose = () =>{
        if(icon === 1){
          window.location.reload();
        } else{
          closeOverlay(true)
        }
    }

    const handleClick = () =>{

    }

  return (
    <div className='errorContainer'>
        <div className='errorSubContain max-h-[250px]'>
            <div className='ClsiconContain'><RiCloseCircleFill onClick={handleClose} id='closeIcon'/></div>
            <div className='iconContainer'>{Iconobj[icon]}</div>
            <span id="errorInfo">{message}</span>

            {isTimeout && <button id='bnw-logout-button' onClick={handleClick}><Link to="/" className='bnw-logout'>Log In</Link></button>}
        </div>
    </div>
  )
}

export default Error
